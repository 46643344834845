import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { useForm, SubmitHandler } from 'react-hook-form';
import { motion } from 'framer-motion';
import { cmProducts } from '../../../store/application/constants';
import { navBarWidthAtom, isNavBarOpenAtom } from '../../../store/atoms';
import Box from '../../../components/box';
import Spacer from '../../../components/surface/spacer';
import { Heading4, Heading5 } from '../../../components/typography/heading';
import Button from '../../../components/button/button';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from '../../../components/helper/dialog';
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
} from '../../../components/dropdown/popover';
import { ButtonText, Caption, Text } from '../../../components/typography/text';
import MSymbol from '../../../components/icon/m-symbol';
import IconButton from '../../../components/button/icon-button';
import WizardBar from '../../../components/bar/wizard-bar';
import ServiceItemButton from '../../../components/button/service-item-button';
import { ServiceItemForm } from '../../../components/helper/service-item-form';
import { CenteredBoxFlex } from '../../../components/surface/centered-box';
import { fadeInOutMotion, fixedLayoutGrowMotion } from '../../../styles/motions';

// TODO: 之後有 reseller 再加回 clientType

const ServiceItemPage = () => {
  const navigate = useNavigate();
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      product: '',
    },
  });

  const onProductClick = (product: string) => {
    setValue('product', product, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };
  const onStartClick: SubmitHandler<{
    product: string;
  }> = (data) => {
    navigate(`./${data.product}/standard`);
  };
  const onLeaveClick = () => {
    navigate(`/applications`);
  };

  useEffect(() => {
    register('product', { required: 'Please select an application type.' });
  }, [register]);

  return (
    <Box css={{ mb: 80 }}>
      {/* Page Header */}
      <Box
        css={{
          px: 20,
          py: 15,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {/* Page Title */}
        <Box>
          <Heading4>{`Create Agreement & Quotation`}</Heading4>
          <Text
            semibold
            css={{ color: '$secondary70' }}>{`Select the service you want to apply for.`}</Text>
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '100px' }} />
      {/* Service items */}
      <ServiceItemForm onSubmit={handleSubmit(onStartClick)}>
        {/* Select product */}
        <Box css={{ width: '100%', maxWidth: 920 }}>
          <Text bold css={{ mb: 10 }}>
            Select an application type:
            <Caption
              as={motion.span}
              initial={'hide'}
              variants={fadeInOutMotion}
              animate={errors.product ? 'show' : 'hide'}
              css={{ color: '$error60' }}>
              {errors.product?.message}
            </Caption>
          </Text>
          <Box css={{ display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap' }}>
            {cmProducts.map((product) => (
              <Box key={product.id} css={{ position: 'relative' }}>
                <ServiceItemButton
                  type="button"
                  data-state={getValues('product') === product.value ? 'active' : 'inactive'}
                  onClick={() => onProductClick(product.value)}
                  data-track-id={`btn-service-item-${product.value}`}
                  disabled={product.value === 'customize'}
                  css={{ opacity: product.value === 'customize' ? 0.5 : 'auto' }}>
                  <CenteredBoxFlex css={{ height: 50 }}>
                    <Box
                      as="img"
                      src={product.logo}
                      css={{ height: product.value === 'customize' ? 27 : 'auto' }}
                    />
                  </CenteredBoxFlex>
                  <CenteredBoxFlex
                    css={{
                      flexDirection: 'row',
                      columnGap: 8,
                    }}>
                    <MSymbol
                      iconName={product.icon}
                      css={{ color: product.icon === 'verified' ? '$primary50' : '$turquoise70' }}
                    />
                    <Text
                      css={{
                        color: '$text-secondary',
                      }}>{`${product.desc}`}</Text>
                  </CenteredBoxFlex>
                  {product.value === 'plps' ? (
                    <Text
                      css={{
                        color: '$warning90',
                      }}>{`Only for support plan 1.0`}</Text>
                  ) : null}
                </ServiceItemButton>
                {product.value === 'gws_simp' || product.value === 'plps' ? (
                  <PopoverRoot>
                    <PopoverTrigger asChild>
                      <IconButton
                        iconName="info"
                        isIconFill={false}
                        color={'primary'}
                        css={{ position: 'absolute', top: 8, right: 8 }}
                      />
                    </PopoverTrigger>
                    <PopoverPortal>
                      <PopoverContent
                        sideOffset={5}
                        align="end"
                        css={{
                          p: '$5',
                          maxWidth: 400,
                          minHeight: 200,
                        }}>
                        {product.value === 'plps' ? (
                          <>
                            <Caption
                              bold
                              css={{
                                color: '$error60',
                              }}>{`Ensure the Subilling account and client information are fully completed on the Oracle before applying.`}</Caption>
                            <Spacer axis="vertical" css={{ $$size: '15px' }} />
                            <Caption>{`The following situations will render the system unable to process contract and quotation requests; please contact Legal directly`}</Caption>
                            <Spacer axis="vertical" css={{ $$size: '15px' }} />
                            <ol style={{ fontSize: 12, paddingInlineStart: 12 }}>
                              <li>Multiple discounts under the same subilling account.</li>
                              <li>
                                No changes allowed to original financial transaction terms. For
                                alterations to GCP discounts or payment terms, please apply for a
                                GCP quotation first.
                              </li>
                              <li>
                                New clients must apply for GCP contracts and quotations first.
                              </li>
                              <li>
                                PLPS cannot be discounted separately; apply for a GCP quotation for
                                any discounts.
                              </li>
                              <li>Merging with any other product or service sales.</li>
                            </ol>
                          </>
                        ) : null}
                      </PopoverContent>
                    </PopoverPortal>
                  </PopoverRoot>
                ) : null}
              </Box>
            ))}
          </Box>
        </Box>
        {/* Wizard Bar */}
        <WizardBar
          css={{ left: navBarWidth }}
          animate={isNavBarOpen ? 'shrink' : 'grow'}
          variants={fixedLayoutGrowMotion(navBarWidth)}>
          {/* WizardBar Left */}
          <DialogRoot>
            {/* Cancel */}
            <DialogTrigger asChild>
              <Button
                size="md"
                variant="borderless"
                color="achromatic"
                css={{ mr: 'auto' }}
                data-track-id="btn-dialog-trigger-cancel">
                <ButtonText size={14} bold>
                  Cancel
                </ButtonText>
              </Button>
            </DialogTrigger>
            <DialogPortal>
              <DialogOverlay />
              <DialogContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
                <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                  {/* Dialog Header */}
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                    <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                    <DialogTitle asChild>
                      <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                    </DialogTitle>
                    <DialogClose asChild>
                      <IconButton iconName="close" size="small" isIconBold={true} />
                    </DialogClose>
                  </Box>
                  {/* Dialog Body */}
                  <DialogDescription css={{ pl: 40 }}>
                    <Text css={{ color: '$text-secondary' }}>
                      Are you sure you want to leave this page?
                    </Text>
                  </DialogDescription>
                  {/* Dialog Footer */}
                  <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                    {/* Leave */}
                    <DialogClose asChild>
                      <Button
                        variant="borderless"
                        color="achromatic"
                        size="md"
                        onClick={() => onLeaveClick()}
                        data-track-id="btn-leave">
                        <ButtonText size={14} bold>
                          Leave
                        </ButtonText>
                      </Button>
                    </DialogClose>
                    {/* Stay */}
                    <DialogClose asChild>
                      <Button size="md" data-track-id="btn-stay">
                        <ButtonText size={14} bold>
                          Stay on this page
                        </ButtonText>
                      </Button>
                    </DialogClose>
                  </Box>
                </Box>
              </DialogContent>
            </DialogPortal>
          </DialogRoot>
          {/* WizardBar Right */}
          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 10, ml: 'auto' }}>
            {/* Start */}
            <Button size="md" type="submit" data-track-id="btn-start">
              <ButtonText size={14} bold>
                Start Form
              </ButtonText>
            </Button>
          </Box>
        </WizardBar>
      </ServiceItemForm>
    </Box>
  );
};

export default ServiceItemPage;
