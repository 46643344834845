import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import usePoller from './hooks/use-poller';
import globalStyles from './styles/global';
import 'react-material-symbols/rounded';
import PrivateRoute from './layouts/private-route';
import { ToastProvider } from './components/helper/toast';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from './components/surface/scroll-area';
import * as Tooltip from '@radix-ui/react-tooltip';
import AppLayout from './layouts/app-layout';
import HomePage from './pages/home-page';
import AppListPage from './pages/application/app-list-page';
import ServiceItemPage from './pages/application/create/service-item-page';
import SubmissionFormPage from './pages/application/create/submission-form-page';
import SubFormPage from './pages/application/create/sub-form-page';
import AppDetailPage from './pages/application/detail/app-detail-page';
import ApplicationDetails from './pages/application/detail/official-standard/application-details';
import CreateApplication from './pages/application/create/official/standard/create-application';
import CustomersPage from './pages/customer/customers-page';
import CreateCompany from './pages/customer/create';
import CustomerDetails from './pages/customer/detail';
import ArchivePage from './pages/archive-page';
import SettingPage from './pages/setting/settings-page';
import LoginLayout from './layouts/login-layout';
import LoginPage from './pages/login-page';
import NotFoundPage from './pages/not-found-page';
import NoAccessPage from './pages/no-access-page';
import { useSetAtom } from 'jotai';
import { hasNewVersionAtom } from './store/atoms';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.REACT_APP_NODE_ENV === 'prod' || false,
      // default: true
    },
  },
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Private Route (Login Needed) */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
        handle={{ crumb: 'Home' }}>
        {/* Home */}
        <Route index element={<HomePage />} />
        {/* App */}
        <Route path="applications" handle={{ crumb: 'Application' }}>
          <Route index element={<AppListPage />} />
          {/* App Service Item */}
          <Route
            path="create"
            element={<ServiceItemPage />}
            handle={{ crumb: 'Create Agreement & Quotation' }}
          />
          {/* App Create (Verified) */}
          <Route
            path="create/:product/:clientType"
            element={<SubFormPage />}
            handle={{ crumb: 'Create Agreement & Quotation' }}
          />
          {/* App Edit (Verified) */}
          <Route
            path="edit/:appType/:product/:clientType/:appId"
            element={<SubFormPage />}
            handle={{ crumb: 'Edit Agreement & Quo.' }}
          />
          {/* App Edit (Simplified) */}
          <Route
            path="edit/:appType/:product/:clientType/:appId"
            element={<SubmissionFormPage />}
            handle={{ crumb: 'Edit Agreement & Quo.' }}
          />
          {/* App Edit (Official) */}
          <Route
            path="edit-application/:applicationId"
            element={<CreateApplication />}
            handle={{ crumb: 'Edit Agreement & Quo.' }}
          />
          {/* App Detail (Simplified + Verified) */}
          <Route
            path="detail/:appType/:product/:clientType/:appId"
            element={<AppDetailPage />}
            handle={{ crumb: 'Details' }}
          />
          {/* App Detail (Official) */}
          <Route
            path="application-details/:applicationId"
            element={<ApplicationDetails />}
            handle={{ crumb: 'Details' }}
          />
        </Route>
        <Route path="customers" handle={{ crumb: 'Customer' }}>
          {/* Customer */}
          <Route index element={<CustomersPage />} />
          {/* Customer Create */}
          <Route
            path="create-company"
            element={<CreateCompany />}
            handle={{ crumb: 'Create Company' }}
          />
          {/* Customer Edit */}
          <Route
            path="edit-company/:customerId"
            element={<CreateCompany />}
            handle={{ crumb: 'Edit Company' }}
          />
          {/* Customer Detail */}
          <Route
            path="customer-details/:customerId"
            element={<CustomerDetails />}
            handle={{ crumb: 'Customer Details' }}
          />
        </Route>
        {/* Setting */}
        <Route path="/setting" element={<SettingPage />} handle={{ crumb: 'Setting' }} />
        {/* Archive */}
        <Route path="/archive" element={<ArchivePage />} handle={{ crumb: 'Archive' }} />
        {/* No Access */}
        <Route path="/no-access" element={<NoAccessPage />} handle={{ crumb: 'No Access' }} />
      </Route>
      {/* Public Route */}
      <Route path="/login" element={<LoginLayout />}>
        {/* Login */}
        <Route index element={<LoginPage />} handle={{ crumb: 'Login' }} />
      </Route>
      {/* Not found Route */}
      <Route path="*" element={<NotFoundPage />} handle={{ crumb: 'Not Found' }} />
    </>
  )
);

const App = () => {
  globalStyles();
  const setHasNewVersion = useSetAtom(hasNewVersionAtom);
  const { isNewVersionAvailable } = usePoller({
    deploymentUrl: window.location.hostname,
  });
  useEffect(() => {
    if (isNewVersionAvailable) {
      console.log('New version available');
      setHasNewVersion(true);
    } else {
      console.log('No new version available');
      setHasNewVersion(false);
    }
  }, [isNewVersionAvailable, setHasNewVersion]);
  return (
    <ScrollAreaRoot id={'root-scroll-area'}>
      <ScrollAreaViewport id={'root-scroll-area-viewport'}>
        <QueryClientProvider client={queryClient}>
          <ToastProvider swipeDirection="left">
            <Tooltip.Provider delayDuration={300} skipDelayDuration={150}>
              <RouterProvider router={router} />
            </Tooltip.Provider>
          </ToastProvider>
          <ReactQueryDevtools initialIsOpen={false} position="top-left" />
        </QueryClientProvider>
        <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </ScrollAreaViewport>
    </ScrollAreaRoot>
  );
};

export default App;
