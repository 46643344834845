import { styled } from '../../styles/stitches.config';

export const ServiceItemFormSimp = styled('form', {
  maxWidth: 920,
  mx: 'auto',
  px: 60,
  display: 'flex',
  flexDirection: 'column',
  rowGap: 40,
  justifyContent: 'center',
});

export const ServiceItemForm = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: 40,
  justifyContent: 'center',
  px: 60,
  alignItems: 'center',
});
