import { ProductType } from '../../../../types/application/constants';
import ContractRequestTabGCP from './contract-request-tab-gcp';
import ContractRequestTabPLPS from './contract-request-tab-plps';
import ContractRequestTabFinops from './contract-request-tab-finops';
import ContractRequestTabGWS from './contract-request-tab-gws';

export interface AppDetailContractRequestTabProps {
  product: ProductType;
  appId: string;
}

const ContractRequestTab = ({ product, appId }: AppDetailContractRequestTabProps) => {
  switch (product) {
    case 'gcp':
      return <ContractRequestTabGCP appId={appId || ''} product={(product as ProductType) || ''} />;
    case 'plps':
      return (
        <ContractRequestTabPLPS appId={appId || ''} product={(product as ProductType) || ''} />
      );
    case 'finops':
      return (
        <ContractRequestTabFinops appId={appId || ''} product={(product as ProductType) || ''} />
      );
    case 'gws':
      return <ContractRequestTabGWS appId={appId || ''} product={(product as ProductType) || ''} />;
    default:
      return null;
  }
};

export default ContractRequestTab;
