import { ProductType } from '../../../../types/application/constants';
import SealRequestTabGCP from './seal-request-tab-gcp';
import SealRequestTabPLPS from './seal-request-tab-plps';
import SealRequestTabFinops from './seal-request-tab-finops';
import SealRequestTabGWS from './seal-request-tab-gws';

export interface AppDetailSealRequestTabProps {
  product: ProductType;
  appId: string;
}

const SealRequestTab = ({ product, appId }: AppDetailSealRequestTabProps) => {
  switch (product) {
    case 'gcp':
      return <SealRequestTabGCP appId={appId || ''} />;
    case 'plps':
      return <SealRequestTabPLPS appId={appId || ''} />;
    case 'finops':
      return <SealRequestTabFinops appId={appId || ''} />;
    case 'gws':
      return <SealRequestTabGWS appId={appId || ''} />;
    default:
      return null;
  }
};

export default SealRequestTab;
