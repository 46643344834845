import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DropdownOption } from '../../../../types';
import { PostPLPSStdAppPayload } from '../../../../types/application/constants';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../store/atoms';
import {
  curSubFormStepAtom,
  postPLPSStdAppPayloadAtom,
  postStdAppAttPayloadAtom,
} from '../../../../store/application/atoms';
import { yesNoOptions } from '../../../../store/constants';
import {
  usePatchStdApp,
  usePostStdApp,
} from '../../../../services/application/verified-standard/app.api';
import { usePostStdAppAtt } from '../../../../services/application/verified-standard/attachment.api';
import {
  useGetPLPSBillingAccountOptions,
  useGetPLPSSupportPlanOptions,
} from '../../../../services/application/verified-standard/dropdown.api';
import { scrollToFirstError, scrollToTop } from '../../../../utils/scroll';
import Box from '../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import { Heading5 } from '../../../../components/typography/heading';
import Spacer from '../../../../components/surface/spacer';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import RadioGroup from '../../../../components/field/radio-group';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import Button from '../../../../components/button/button';
import MSymbol from '../../../../components/icon/m-symbol';
import Combobox from '../../../../components/dropdown/combobox';
import Label from '../../../../components/typography/label';
import TextInput from '../../../../components/field/text-input';
import ChipSelect from '../../../../components/dropdown/chip-select';
import WizardBar from '../../../../components/bar/wizard-bar';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionPaperHeader,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import ServiceItemButton from '../../../../components/button/service-item-button';
import { fixedLayoutGrowMotion } from '../../../../styles/motions';
import LogoPLPS from '../../../../assets/images/logo-plps.svg';

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({
  billing_account: Yup.object().shape({
    value: Yup.string().required(),
  }),
  payment_term: Yup.object().shape({
    value: Yup.number()
      .typeError('Must specify a number')
      .moreThan(0, 'Must be a positive number')
      .integer('Must be an integer')
      .required('This field is required'),
  }),
  official_pricing_discount_type: Yup.object().shape({
    value: Yup.string().required(),
  }),
  official_pricing_discount_value: Yup.number()
    .typeError('Must specify a number')
    .test('official_pricing_discount_value', '', (value, context) => {
      const curOfficialPricingDiscountType =
        context.from?.[0].value.official_pricing_discount_type.value;
      if (curOfficialPricingDiscountType === 'percentage_discount') {
        return !!value || context.createError({ message: `This field is required` });
      } else if (curOfficialPricingDiscountType === 'fixed_amount_discount') {
        return (
          (!!value &&
            parseFloat(value.toString()) > 0 &&
            /^\d+(\.\d{1,2})?$/.test(value.toString())) ||
          context.createError({ message: `Positive number, up to 2 decimal places` })
        );
      } else {
        return true;
      }
    }),
  official_pricing_discount_currency: Yup.object().shape({
    value: Yup.string().test(
      'official_pricing_discount_currency',
      'This field is required',
      (value, context) => {
        const curOfficialPricingDiscountType =
          context.from?.[1].value.official_pricing_discount_type.value;
        return curOfficialPricingDiscountType === 'no_discount' || value !== '';
      }
    ),
  }),
  support_plan: Yup.object().shape({
    value: Yup.string().required(),
  }),
  is_issued_form: Yup.string().required(),
});

const ServiceInfo = () => {
  const navigate = useNavigate();
  const { appId } = useParams();
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const setCurSubFormStep = useSetAtom(curSubFormStepAtom);
  const [postStdAppPayload, setPostStdAppPayload] = useAtom(postPLPSStdAppPayloadAtom);
  const postStdAppAttPayload = useAtomValue(postStdAppAttPayloadAtom);

  const { control, handleSubmit, getValues, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: postStdAppPayload,
    resolver: yupResolver(validationSchema),
  });

  const curIsSupportPlanMatched = useWatch({ control, name: 'is_support_plan_matched' });

  const billingAccountOptionsQuery = useGetPLPSBillingAccountOptions({
    client: getValues('client.value'),
  });
  const supportPlanOptionsQuery = useGetPLPSSupportPlanOptions({
    situation: curIsSupportPlanMatched ? 'default' : 'unmatched',
  });
  const postAppMutation = usePostStdApp();
  const patchAppMutation = usePatchStdApp({ appId: appId || '' });
  const postAttachmentMutation = usePostStdAppAtt({
    appId,
    onSuccess: (res, appStatus) => {
      if (appId) {
        patchAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
      } else {
        if (appStatus === 'draft') {
          postAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
        } else {
          postAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
        }
      }
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const onBillingAccountChange = (newValue: { name: string; value: DropdownOption['value'] }) => {
    setValue('billing_account', newValue, { shouldValidate: true });
    const selectedBillingAccount = billingAccountOptionsQuery.data?.find(
      (option) => option.value === newValue.value
    );
    if (selectedBillingAccount?.other_info) {
      const supportPlans = selectedBillingAccount.other_info.support_plans;
      if (supportPlans && Array.isArray(supportPlans)) {
        const isSupportPlanMatched = [
          'enhanced',
          'platinum',
          'msp_standard',
          'msp_platinum',
        ].includes(supportPlans?.[0]?.value);
        setValue('is_support_plan_matched', isSupportPlanMatched);
        if (isSupportPlanMatched && supportPlans.length > 0) {
          setValue('support_plan', supportPlans[0], {
            shouldValidate: true,
          });
        }
      }
      setValue('payment_term', selectedBillingAccount.other_info.payment_term, {
        shouldValidate: true,
      });
      setValue(
        'official_pricing_discount_type',
        selectedBillingAccount.other_info.official_pricing_discount_type,
        {
          shouldValidate: true,
        }
      );
      setValue(
        'official_pricing_discount_value',
        selectedBillingAccount.other_info.official_pricing_discount_value,
        {
          shouldValidate: true,
        }
      );
      setValue(
        'official_pricing_discount_currency',
        selectedBillingAccount.other_info.official_pricing_discount_currency,
        {
          shouldValidate: true,
        }
      );
    }
  };
  const onOfficialPricingDiscountTypeChange = (newValue: {
    name: string;
    value: DropdownOption['value'];
  }) => {
    setValue('official_pricing_discount_type', newValue, { shouldValidate: true });
    setValue('official_pricing_discount_value', 0, { shouldValidate: true });
  };

  const onBackClick = () => {
    setPostStdAppPayload((prev) => ({ ...prev, ...getValues() }));
    setCurSubFormStep(1);
  };
  const onNextClick: SubmitHandler<PostPLPSStdAppPayload> = (data) => {
    setPostStdAppPayload((prev) => ({ ...prev, ...data }));
    setCurSubFormStep(3);
  };
  const onNextError = (errors: unknown) => {
    scrollToFirstError(errors);
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({
      ...postStdAppAttPayload,
      appStatus: appId ? getValues('status') : 'draft',
    });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <form onSubmit={handleSubmit(onNextClick, onNextError)}>
      {/* Service */}
      <SubmissionPaper>
        {/* Header */}
        <SubmissionPaperHeader>
          <Heading5>Service</Heading5>
          <ColorfulChip text="PLPS" color="green" variant="solid" />
        </SubmissionPaperHeader>
        {/* Payment Details */}
        <SubmissionSection>
          <SubmissionSectionHeader>
            <Text semibold>Payment Details</Text>
          </SubmissionSectionHeader>
          <ServiceItemButton type="button" data-state="active" showcase>
            <Box as="img" src={LogoPLPS} css={{ py: 14 }} />
          </ServiceItemButton>
          <SubmissionRow>
            {/* Billing Account */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="billing_account"
                render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Billing Account ID & Name"
                    placeholder="Select a billing account"
                    isLoading={billingAccountOptionsQuery.isLoading}
                    options={billingAccountOptionsQuery.data || []}
                    optionSize={'lg'}
                    fieldName={name}
                    value={value}
                    onChange={onBillingAccountChange}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Payment Term */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="payment_term"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Payment Term"
                    placeholder="Select a payment term"
                    isLoading={false}
                    options={[]}
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    disabled
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
            {/* Support Plan */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="support_plan"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Support Plan"
                    placeholder="Select a support plan"
                    isLoading={supportPlanOptionsQuery.isLoading}
                    options={supportPlanOptionsQuery.data || []}
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    disabled={curIsSupportPlanMatched}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Official Pricing Discount */}
            <SubmissionColumn>
              <Label>
                <Caption>
                  Official Pricing Discount
                  <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                </Caption>
              </Label>
              <SubmissionRow columnGapSize="5">
                {/* Official Pricing Discount Type  */}
                <Box css={{ width: `calc(50% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_type"
                    render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                      <Combobox
                        placeholder="Select a discount type"
                        isLoading={false}
                        options={[]}
                        optionSize="lg"
                        fieldName={name}
                        value={value}
                        onChange={onOfficialPricingDiscountTypeChange}
                        disabled
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </Box>
                {/* Official Pricing Discount Value  */}
                <Box css={{ width: `calc(30% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_value"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <TextInput
                        placeholder="Enter a discount value"
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        disabled
                        isError={invalid}
                        error={error?.message}
                        endElement={
                          <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                            %
                          </ButtonText>
                        }
                      />
                    )}
                  />
                </Box>
                {/* Official Pricing Discount Currency */}
                <Box css={{ width: `calc(20% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_currency"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <ChipSelect
                        placeholder="Select a discount currency"
                        isLoading={false}
                        options={[]}
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        disabled
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </Box>
              </SubmissionRow>
            </SubmissionColumn>
            {/* Will customer issue PO / LOA / Order Form / other ? */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="is_issued_form"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    required
                    label="Will customer issue PO / LOA / Order Form / other ?"
                    value={value}
                    options={yesNoOptions}
                    onChange={onChange}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
        </SubmissionSection>
      </SubmissionPaper>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button size="md" variant="borderless" color="achromatic" css={{ mr: 'auto' }}>
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this page?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      onClick={() => onLeaveClick()}>
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  {getValues('status') === 'rejected' ? null : (
                    <Button
                      variant="outlined"
                      size="md"
                      onClick={() => onSaveClick()}
                      isLoading={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      css={{ width: appId ? 67 : 123 }}>
                      <ButtonText size={14} bold>
                        {appId ? 'Save' : 'Save as draft'}
                      </ButtonText>
                    </Button>
                  )}
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }>
                      <ButtonText size={14} bold>
                        Stay on this page
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button size="md" variant="outlined" onClick={() => onBackClick()}>
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Next */}
          <Button type="submit" size="md">
            <ButtonText size={14} bold>
              Next
            </ButtonText>
          </Button>
        </Box>
      </WizardBar>
    </form>
  );
};

export default ServiceInfo;
