import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { SubmitHandler, useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isInt from 'validator/lib/isInt';
import { DropdownOption, DateISO } from '../../../../types';
import { PostGCPStdAppPayload, ProductType } from '../../../../types/application/constants';
import {
  authUserAtom,
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../store/atoms';
import {
  curSubFormStepAtom,
  postStdAppAttPayloadAtom,
  postGCPStdAppPayloadAtom,
  isEditDataLoadedAtom,
} from '../../../../store/application/atoms';
import { cmCustomerTypes, cmProducts } from '../../../../store/application/constants';
import {
  usePatchStdApp,
  usePostStdApp,
} from '../../../../services/application/verified-standard/app.api';
import { usePostStdAppAtt } from '../../../../services/application/verified-standard/attachment.api';
import {
  useGetClientOptions,
  useGetCMAttentionToOptions,
  useGetCMLegalEntityOptions,
  useGetCountryCallingCodeOptions,
  useGetCountryCodeOptions,
  useGetCurrencyOptions,
  useGetExchangeRateOptions,
  useGetMonthOptions,
  useGetAgreementEndDate,
} from '../../../../services/application/verified-standard/dropdown.api';
import {
  useGetAppDetailContractRequestTabGCP,
  useGetAppDetailSealRequestTabGCP,
} from '../../../../services/application/verified-standard/app-detail-tabs.api';
import { scrollToFirstError, scrollToTop } from '../../../../utils/scroll';
import {
  isTaxRegNumberFieldOptional,
  isTaxRegNumberFieldRequired,
  taxRegNumberFieldLabel,
} from '../../../../utils/tax-reg-number-field';
import { getStdAttFileObjects } from '../../../../utils/get-file-object';
import Box from '../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import { Heading5 } from '../../../../components/typography/heading';
import Spacer from '../../../../components/surface/spacer';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from '../../../../components/helper/dialog';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import Button from '../../../../components/button/button';
import MSymbol from '../../../../components/icon/m-symbol';
import IconButton from '../../../../components/button/icon-button';
import Combobox from '../../../../components/dropdown/combobox';
import RadioGroup from '../../../../components/field/radio-group';
import Combofield from '../../../../components/dropdown/combofield';
import Label from '../../../../components/typography/label';
import TextInput from '../../../../components/field/text-input';
import DayPicker from '../../../../components/dropdown/day-picker';
import WizardBar from '../../../../components/bar/wizard-bar';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionPaperHeader,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import Spinner from '../../../../components/helper/spinner';
import { CenteredBoxAbsolute } from '../../../../components/surface/centered-box';
import ServiceItemButton from '../../../../components/button/service-item-button';
import { fixedLayoutGrowMotion } from '../../../../styles/motions';
import LogoGoogleCloud from '../../../../assets/images/logo-google-cloud.svg';
import ErrorBG from '../../../../assets/images/bg-error.svg';

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({
  cm_legal_entity: Yup.object().shape({
    value: Yup.string().required(),
  }),
  cm_attention_to: Yup.object().shape({
    value: Yup.string().required(),
  }),
  cm_country_calling_code: Yup.object().shape({
    value: Yup.string().notRequired(),
  }),
  cm_phone_number: Yup.string()
    .optional()
    .test(
      'cm_phone_number',
      'Invalid phone number email',
      (value) => value === '' || isMobilePhone(value || '')
    ),
  cm_extension_number: Yup.string()
    .nullable()
    .notRequired()
    .test('cm_extension_number', 'Must be number', (value) => {
      return value === '' || isInt(value || '');
    }),
  quotation_validity: Yup.date().required('This field is required'),
  agreement_term: Yup.number()
    .typeError('Must specify a number')
    .moreThan(0, 'Must be a positive number')
    .integer('Must be an integer')
    .required('This field is required'),
  automatically_renew_term: Yup.number()
    .typeError('Must specify a number')
    .moreThan(-1, 'Must greater or equal to 0')
    .integer('Must be an integer')
    .required('This field is required'),
  currency: Yup.object().shape({
    value: Yup.string().required('This field is required'),
  }),
  exchange_rate: Yup.object().shape({
    value: Yup.string().test('exchange_rate', 'This field is required', (value, context) => {
      const curCurrency = context.from?.[1].value.currency.value;
      return (
        (curCurrency !== 'TWD' && curCurrency !== 'HKD' && curCurrency !== 'PHP') || value !== ''
      );
    }),
  }),
  agreement_commencement_date: Yup.date().required('This field is required'),
  agreement_end_date: Yup.date().required('This field is required'),
  customer_type: Yup.string().required('This field is required'),
  client: Yup.object().shape({
    value: Yup.string().required('This field is required'),
  }),
  client_source: Yup.string().optional(),
  client_country: Yup.object().shape({
    value: Yup.string().required('This field is required'),
  }),
  tax_reg_number: Yup.string().test(
    'tax_reg_number',
    'This field is required',
    (value, context) => {
      const curClientCountry = context.from?.[0].value.client_country.value;
      return curClientCountry !== 'TW' || value !== '';
    }
  ),
  client_address: Yup.string().notRequired(),
  client_attention_to: Yup.string().required('This field is required'),
  client_email: Yup.string()
    .email('Invalid email format')
    .required('This field is required')
    .test('client_email', 'Invalid email format', (value) => isEmail(value)),
  client_country_calling_code: Yup.object().shape({
    value: Yup.string().notRequired(),
  }),
  client_phone_number: Yup.string()
    .nullable()
    .notRequired()
    .test('client_phone_number', 'Invalid phone number format', (value) => {
      return value === '' || isMobilePhone(value || '');
    }),
  client_extension_number: Yup.string()
    .nullable()
    .notRequired()
    .test('client_extension_number', 'Must be number', (value) => {
      return value === '' || isInt(value || '');
    }),
});

const GeneralInfo = () => {
  const navigate = useNavigate();
  const { product, appId } = useParams();
  const [isEditDataLoaded, setIsEditDataLoaded] = useAtom(isEditDataLoadedAtom);
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const authUser = useAtomValue(authUserAtom);
  const setCurSubFormStep = useSetAtom(curSubFormStepAtom);
  const [postStdAppPayload, setPostStdAppPayload] = useAtom(postGCPStdAppPayloadAtom);
  const [postStdAppAttPayload, setPostStdAppAttPayload] = useAtom(postStdAppAttPayloadAtom);
  const [isClientCountryDisabled, setIsClientCountryDisabled] = useState(true);
  const [isClientAddressDisabled, setIsClientAddressDisabled] = useState(true);
  const [isTaxRegNumberDisabled, setIsTaxRegNumberDisabled] = useState(true);

  const { control, handleSubmit, getValues, setValue, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: postStdAppPayload,
    resolver: yupResolver(validationSchema),
  });
  const curCMLegalEntity = useWatch({ control, name: 'cm_legal_entity' });
  const curCMAttentionTo = useWatch({ control, name: 'cm_attention_to' });
  const curCurrency = useWatch({ control, name: 'currency' });
  const curAgreementTerm = useWatch({ control, name: 'agreement_term' });
  const curAgreementCommencementDate = useWatch({ control, name: 'agreement_commencement_date' });
  const curClientCountryCode = useWatch({ control, name: 'client_country' });

  const cmLegalEntityOptionsQuery = useGetCMLegalEntityOptions();
  const cmAttentionToOptionsQuery = useGetCMAttentionToOptions();
  const cmCountryCallingCodeOptionsQuery = useGetCountryCallingCodeOptions({ isCMOnly: true });
  const agreementTermOptionsQuery = useGetMonthOptions({ field: 'agreement_term' });
  const autoRenewTermOptionsQuery = useGetMonthOptions({ field: 'auto_renew_term' });
  const currencyOptionsQuery = useGetCurrencyOptions({
    currencyType: 'normal_currencies',
    cmLegalEntity: curCMLegalEntity.value,
  });
  const exchangeRateOptionsQuery = useGetExchangeRateOptions({
    product: 'gcp',
    cmLegalEntity: curCMLegalEntity.value,
    currency: curCurrency.value,
  });
  const clientOptionsQuery = useGetClientOptions();
  const clientCountryCallingCodeOptionsQuery = useGetCountryCallingCodeOptions({ isCMOnly: false });
  const clientCountryCodeOptionsQuery = useGetCountryCodeOptions({ isCMOnly: false });
  const agreementEndDateQuery = useGetAgreementEndDate({
    agreementTerm: curAgreementTerm,
    agreementCommencementDate: curAgreementCommencementDate,
  });
  const postAppMutation = usePostStdApp();
  const patchAppMutation = usePatchStdApp({ appId: appId || '' });
  const postAttachmentMutation = usePostStdAppAtt({
    appId,
    onSuccess: (res, appStatus) => {
      if (appId) {
        patchAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
      } else {
        if (appStatus === 'draft') {
          postAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
        } else {
          postAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
        }
      }
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const appDetailContractRequestTabQuery = useGetAppDetailContractRequestTabGCP({
    product: (product as ProductType) || '',
    appId: appId || '',
  });
  const appDetailSealRequestTabQuery = useGetAppDetailSealRequestTabGCP({
    appId: appId || '',
  });

  const onCMLegalEntityChange = (newValue: { name: string; value: DropdownOption['value'] }) => {
    setValue('cm_legal_entity', newValue, { shouldValidate: true });
    setValue('currency', { name: '', value: '' }, { shouldValidate: true });
    setValue('exchange_rate', { name: '', value: '' }, { shouldValidate: true });
    setValue('official_pricing_discount_currency', { name: '', value: '' });
    setValue('seal_type', { name: '', value: '' });
  };
  const onCMAttentionToChange = useCallback(
    (newValue: { name: string; value: DropdownOption['value'] }) => {
      const selectedCMAttentionTo = cmAttentionToOptionsQuery.data?.find(
        (option) => option.value === newValue.value
      );
      setValue('cm_attention_to', newValue, { shouldValidate: true });
      setValue('cm_email', selectedCMAttentionTo?.desc || '');
      setValue('cm_picture', selectedCMAttentionTo?.picture || '');
      setValue('assignee', newValue);
      if (selectedCMAttentionTo) {
        setValue('application_unit', selectedCMAttentionTo.chip || '');
      }
    },
    [cmAttentionToOptionsQuery.data, setValue]
  );
  const onAgreementTermChange = (newValue: string) => {
    setValue('agreement_term', newValue, { shouldValidate: true });
  };
  const onCurrencyChange = (newValue: { name: string; value: DropdownOption['value'] }) => {
    setValue('currency', newValue, { shouldValidate: true });
    setValue('exchange_rate', { name: '', value: '' }, { shouldValidate: true });
    setValue('official_pricing_discount_currency', { name: '', value: '' });
  };
  const onExchangeRateChange = (newValue: { name: string; value: DropdownOption['value'] }) => {
    setValue('exchange_rate', newValue, { shouldValidate: true });
    setValue('official_pricing_discount_currency', { name: '', value: '' });
  };
  const onAgreementCommencementDateChange = (newValue: DateISO) => {
    setValue('agreement_commencement_date', newValue);
  };
  const onClientChange = (newValue: { name: string; value: DropdownOption['value'] }) => {
    setValue('client', newValue, { shouldValidate: true });
    setValue('deliver_to', newValue);
    const selectedClient = clientOptionsQuery.data?.find(
      (option) => option.value === newValue.value
    );
    if (selectedClient?.other_info) {
      setValue('client_source', selectedClient.chip);
      setValue(
        'client_country',
        {
          name: selectedClient.other_info.country || '',
          value: selectedClient.other_info.country_code || '',
        },
        {
          shouldValidate: true,
        }
      );
      setIsClientCountryDisabled(
        selectedClient.other_info.country && selectedClient.other_info.country_code ? true : false
      );
      setValue('tax_reg_number', selectedClient.other_info.tax_reg_number || '', {
        shouldValidate: true,
      });
      setIsTaxRegNumberDisabled(selectedClient.other_info.tax_reg_number ? true : false);
      setValue('client_address', selectedClient.other_info.address || '', { shouldValidate: true });
      setIsClientAddressDisabled(selectedClient.other_info.address ? true : false);
      setPostStdAppAttPayload((prev) => ({
        ...prev,
        company_registration: null,
      }));
    }
  };
  const onBackClick = () => {
    navigate('/applications/create');
  };
  const onNextClick: SubmitHandler<PostGCPStdAppPayload> = (data) => {
    setPostStdAppPayload((prev) => ({ ...prev, ...data }));
    setCurSubFormStep(2);
  };
  const onNextError = (errors: unknown) => {
    scrollToFirstError(errors);
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({
      ...postStdAppAttPayload,
      appStatus: appId ? getValues('status') : 'draft',
    });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  useEffect(() => {
    if (currencyOptionsQuery.isSuccess && currencyOptionsQuery.data.length > 0) {
      if (!getValues('currency.value')) {
        setValue(
          'currency',
          {
            name: currencyOptionsQuery.data[0].name,
            value: currencyOptionsQuery.data[0].value,
          },
          { shouldValidate: true }
        );
      }
    }
  }, [currencyOptionsQuery.data, currencyOptionsQuery.isSuccess, getValues, setValue]);

  useEffect(() => {
    if (agreementEndDateQuery.isSuccess && agreementEndDateQuery.data) {
      setValue('agreement_end_date', agreementEndDateQuery.data.agreement_end_date, {
        shouldValidate: true,
      });
    }
  }, [agreementEndDateQuery.data, agreementEndDateQuery.isSuccess, setValue]);

  useEffect(() => {
    if (agreementEndDateQuery.isError) {
      setValue('agreement_end_date', undefined, {
        shouldValidate: true,
      });
    }
  }, [agreementEndDateQuery.isError, setValue]);

  useEffect(() => {
    const setEditData = async () => {
      if (appDetailContractRequestTabQuery.isSuccess && appDetailSealRequestTabQuery.isSuccess) {
        const {
          contract_file_list: contractFileList,
          cm_attention_to_picture: cmPicture,
          ...pickedContractData
        } = appDetailContractRequestTabQuery.data;
        const {
          seal_file_list: sealFileList,
          assignee_picture: assigneePicture,
          ...pickedSealData
        } = appDetailSealRequestTabQuery.data;
        const gcpStdAttFileObjects = await getStdAttFileObjects({
          contractFileList,
          sealFileList,
        });
        reset({ ...pickedContractData, ...pickedSealData });
        setPostStdAppAttPayload(gcpStdAttFileObjects);
        setIsEditDataLoaded(true);
      }
    };
    if (appId && !isEditDataLoaded) {
      setEditData();
    }
  }, [
    appId,
    appDetailContractRequestTabQuery.data,
    appDetailContractRequestTabQuery.isSuccess,
    appDetailSealRequestTabQuery.data,
    appDetailSealRequestTabQuery.isSuccess,
    isEditDataLoaded,
    setIsEditDataLoaded,
    reset,
    setPostStdAppAttPayload,
  ]);

  useEffect(() => {
    if (
      cmAttentionToOptionsQuery.isSuccess &&
      cmAttentionToOptionsQuery.data &&
      !curCMAttentionTo.value &&
      authUser !== null
    ) {
      onCMAttentionToChange({ name: authUser.name, value: authUser.id });
    }
  }, [
    authUser,
    curCMAttentionTo.value,
    cmAttentionToOptionsQuery.data,
    cmAttentionToOptionsQuery.isSuccess,
    onCMAttentionToChange,
  ]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      {appDetailContractRequestTabQuery.isLoading || appDetailSealRequestTabQuery.isLoading ? (
        <CenteredBoxAbsolute>
          <Spinner size="large" />
        </CenteredBoxAbsolute>
      ) : appDetailContractRequestTabQuery.isError || appDetailSealRequestTabQuery.isError ? (
        <CenteredBoxAbsolute>
          <Box as="img" src={ErrorBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Text semibold css={{ textAlign: 'center' }}>{`Something went wrong`}</Text>
        </CenteredBoxAbsolute>
      ) : (
        <form onSubmit={handleSubmit(onNextClick, onNextError)}>
          {/* CloudMile */}
          <SubmissionPaper>
            {/* Header */}
            <SubmissionPaperHeader>
              <Heading5>CloudMile</Heading5>
              <ColorfulChip text="GCP" color="sky" variant="solid" />
            </SubmissionPaperHeader>
            {/* General Info */}
            <SubmissionSection>
              <SubmissionSectionHeader>
                <Text semibold>General Information</Text>
              </SubmissionSectionHeader>
              <ServiceItemButton type="button" data-state="active" showcase>
                <Box as="img" src={LogoGoogleCloud} css={{ py: 14 }} />
              </ServiceItemButton>
              <SubmissionRow>
                {/* CM Legal Entity */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="cm_legal_entity"
                    render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                      <Combobox
                        required
                        label="CM-Legal Entity"
                        placeholder="Select a legal entity"
                        isLoading={cmLegalEntityOptionsQuery.isLoading}
                        options={cmLegalEntityOptionsQuery.data || []}
                        fieldName={name}
                        value={value}
                        onChange={onCMLegalEntityChange}
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </SubmissionColumn>
                {/* Product */}
                <SubmissionColumn>
                  <Combobox
                    required
                    label="Product"
                    placeholder="Select a product"
                    options={cmProducts}
                    value={cmProducts[0]}
                    onChange={() => {}}
                    disabled
                  />
                </SubmissionColumn>
              </SubmissionRow>
              <SubmissionRow>
                {/* Attention to */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="cm_attention_to"
                    render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                      <Combobox
                        required
                        label="Attention To"
                        placeholder="Select a person"
                        isLoading={cmAttentionToOptionsQuery.isLoading}
                        options={cmAttentionToOptionsQuery.data || []}
                        optionSize="md"
                        fieldName={name}
                        value={value}
                        onChange={onCMAttentionToChange}
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                        disabled={cmAttentionToOptionsQuery.isLoading}
                        triggerEndElement={
                          cmAttentionToOptionsQuery.isLoading ? (
                            <Spinner size="mini" css={{ mr: 8 }} />
                          ) : undefined
                        }
                      />
                    )}
                  />
                </SubmissionColumn>
                <SubmissionColumn>
                  <Label>
                    <Caption>Phone Number</Caption>
                  </Label>
                  <SubmissionRow columnGapSize="10">
                    {/* CM Country Calling Code */}
                    <SubmissionColumn widthRatio={'4'} columnGapSize="10">
                      <Controller
                        control={control}
                        name="cm_country_calling_code"
                        render={({
                          field: { onChange, value, name },
                          fieldState: { invalid, error },
                        }) => (
                          <Combobox
                            placeholder="Select a country calling code"
                            isLoading={cmCountryCallingCodeOptionsQuery.isLoading}
                            options={cmCountryCallingCodeOptionsQuery.data || []}
                            fieldName={name}
                            value={value}
                            onChange={onChange}
                            isError={invalid}
                            error={error ? 'This field is required' : ''}
                          />
                        )}
                      />
                    </SubmissionColumn>
                    {/* CM Phone Number */}
                    <SubmissionColumn widthRatio={'4'} columnGapSize="10">
                      <Controller
                        control={control}
                        name="cm_phone_number"
                        render={({
                          field: { onChange, value },
                          fieldState: { invalid, error },
                        }) => (
                          <TextInput
                            placeholder="Enter a phone number"
                            value={value}
                            onChange={onChange}
                            isError={invalid}
                            error={error?.message}
                          />
                        )}
                      />
                    </SubmissionColumn>
                    {/* CM Phone Extension Number */}
                    <SubmissionColumn widthRatio={'2'} columnGapSize="10">
                      <Controller
                        control={control}
                        name="cm_extension_number"
                        render={({
                          field: { onChange, value },
                          fieldState: { invalid, error },
                        }) => (
                          <TextInput
                            placeholder="Enter an extension number"
                            value={value}
                            onChange={onChange}
                            textInputContentColumnGap={0}
                            startElement={
                              <ButtonText size={14} css={{ color: '$text-secondary', ml: 6 }}>
                                ＃
                              </ButtonText>
                            }
                            isError={invalid}
                            error={error?.message}
                          />
                        )}
                      />
                    </SubmissionColumn>
                  </SubmissionRow>
                </SubmissionColumn>
              </SubmissionRow>
            </SubmissionSection>
            {/* Details */}
            <SubmissionSection>
              <SubmissionSectionHeader>
                <Text semibold>Details</Text>
              </SubmissionSectionHeader>
              <SubmissionRow>
                {/* Quotation Validity */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="quotation_validity"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <DayPicker
                        required
                        label="Quotation Validity"
                        placeholder="Select a date"
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        isError={invalid}
                        error={error?.message}
                        minDate={new Date()}
                      />
                    )}
                  />
                </SubmissionColumn>
                {/* Agreement Term */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="agreement_term"
                    render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                      <Combofield
                        required
                        label="Agreement Term"
                        placeholder="Enter an agreement term"
                        isLoading={agreementTermOptionsQuery.isLoading}
                        options={agreementTermOptionsQuery.data || []}
                        fieldName={name}
                        value={value}
                        onChange={(newValue) => onAgreementTermChange(newValue)}
                        isError={invalid}
                        error={error?.message}
                        triggerEndElement={
                          <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                            Months
                          </ButtonText>
                        }
                      />
                    )}
                  />
                </SubmissionColumn>
              </SubmissionRow>
              <SubmissionRow>
                {/* Automatically Renew Term */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="automatically_renew_term"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <Combofield
                        required
                        label="Automatically Renew Term"
                        placeholder="Enter an automatically renew term"
                        isLoading={autoRenewTermOptionsQuery.isLoading}
                        options={autoRenewTermOptionsQuery.data || []}
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        isError={invalid}
                        error={error?.message}
                        triggerEndElement={
                          <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                            Months
                          </ButtonText>
                        }
                      />
                    )}
                  />
                </SubmissionColumn>
                <SubmissionColumn>
                  <SubmissionRow columnGapSize="10">
                    {/* Currency */}
                    <SubmissionColumn widthRatio={'3'} columnGapSize="10">
                      <Controller
                        control={control}
                        name="currency"
                        render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                          <Combobox
                            required
                            label="Currency"
                            placeholder="Select a currency"
                            isLoading={currencyOptionsQuery.isLoading}
                            options={currencyOptionsQuery.data || []}
                            fieldName={name}
                            value={value}
                            onChange={onCurrencyChange}
                            isError={invalid}
                            error={error ? 'This field is required' : ''}
                          />
                        )}
                      />
                    </SubmissionColumn>
                    {/* Exchange Rate */}
                    <SubmissionColumn widthRatio={'7'} columnGapSize="10">
                      <Controller
                        control={control}
                        name="exchange_rate"
                        render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                          <Combobox
                            required={
                              curCurrency.value === 'TWD' ||
                              curCurrency.value === 'HKD' ||
                              curCurrency.value === 'PHP'
                            }
                            label="Exchange Rate"
                            placeholder="Select a exchange rate"
                            isLoading={exchangeRateOptionsQuery.isLoading}
                            options={exchangeRateOptionsQuery.data || []}
                            optionSize="md"
                            fieldName={name}
                            value={value}
                            onChange={onExchangeRateChange}
                            disabled={
                              !exchangeRateOptionsQuery.data ||
                              exchangeRateOptionsQuery.data.length <= 0
                            }
                            isError={invalid}
                            error={error ? 'This field is required' : ''}
                          />
                        )}
                      />
                    </SubmissionColumn>
                  </SubmissionRow>
                </SubmissionColumn>
              </SubmissionRow>
              <SubmissionRow>
                {/* Agreement Commencement Date */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="agreement_commencement_date"
                    render={({ field: { value }, fieldState: { invalid, error } }) => (
                      <DayPicker
                        required
                        label="Agreement Commencement Date"
                        placeholder="Select a date"
                        value={value}
                        onChange={onAgreementCommencementDateChange}
                        isError={invalid}
                        error={error?.message}
                        minDate={new Date()}
                      />
                    )}
                  />
                </SubmissionColumn>
                {/* Agreement End Date */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="agreement_end_date"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <DayPicker
                        disabled
                        required
                        label="Agreement End Date"
                        placeholder="Select a date"
                        value={value}
                        onChange={onChange}
                        minDate={postStdAppPayload.agreement_commencement_date}
                        triggerEndElement={
                          agreementEndDateQuery.isLoading ? (
                            <Spinner size="mini" css={{ mr: 8 }} />
                          ) : null
                        }
                        isError={invalid}
                        error={error?.message}
                      />
                    )}
                  />
                </SubmissionColumn>
              </SubmissionRow>
            </SubmissionSection>
          </SubmissionPaper>
          <Spacer axis="vertical" css={{ $$size: '20px' }} />
          {/* Customer */}
          <SubmissionPaper>
            <SubmissionPaperHeader>
              <Heading5>Customer</Heading5>
            </SubmissionPaperHeader>
            <SubmissionSection>
              <SubmissionSectionHeader>
                <Text semibold>Customer Information</Text>
              </SubmissionSectionHeader>
              <SubmissionRow>
                {/* Has this customer ever signed a GCP quotation ? */}
                <SubmissionColumn widthRatio={'10'}>
                  <Controller
                    control={control}
                    name="customer_type"
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        required
                        label="Has this customer ever signed a GCP quotation ?"
                        value={value}
                        options={cmCustomerTypes}
                        onChange={onChange}
                      />
                    )}
                  />
                </SubmissionColumn>
              </SubmissionRow>
              <SubmissionRow>
                {/* Client */}
                <SubmissionColumn widthRatio={'10'}>
                  <Controller
                    control={control}
                    name="client"
                    render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                      <Combobox
                        required
                        label="Client Name"
                        placeholder="Select a client"
                        isLoading={clientOptionsQuery.isLoading}
                        options={clientOptionsQuery.data || []}
                        optionSize="lg"
                        fieldName={name}
                        value={value}
                        onChange={onClientChange}
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </SubmissionColumn>
              </SubmissionRow>
              <SubmissionRow>
                {/* Client Country */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="client_country"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <Combobox
                        required
                        label="Client Country"
                        placeholder="Select a country"
                        isLoading={clientCountryCodeOptionsQuery.isLoading}
                        options={clientCountryCodeOptionsQuery.data || []}
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        disabled={isClientCountryDisabled}
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </SubmissionColumn>
                {/* Tax Reg Number */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="tax_reg_number"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <TextInput
                        required={isTaxRegNumberFieldRequired(curClientCountryCode.value)}
                        label={taxRegNumberFieldLabel(curClientCountryCode.value)}
                        placeholder={`Enter a ${taxRegNumberFieldLabel(
                          curClientCountryCode.value
                        )}`}
                        value={value}
                        onChange={onChange}
                        disabled={
                          (!isTaxRegNumberFieldRequired(curClientCountryCode.value) &&
                            !isTaxRegNumberFieldOptional(curClientCountryCode.value)) ||
                          isTaxRegNumberDisabled
                        }
                        isError={invalid}
                        error={error?.message}
                      />
                    )}
                  />
                </SubmissionColumn>
              </SubmissionRow>
              <SubmissionRow>
                {/* Client Address */}
                <SubmissionColumn widthRatio={'10'}>
                  <Controller
                    control={control}
                    name="client_address"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <TextInput
                        label="Address"
                        placeholder="Enter an address"
                        value={value}
                        onChange={onChange}
                        disabled={isClientAddressDisabled}
                        isError={invalid}
                        error={error?.message}
                      />
                    )}
                  />
                </SubmissionColumn>
              </SubmissionRow>
              <SubmissionRow>
                {/* Client Attention To */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="client_attention_to"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <TextInput
                        required
                        label="Attention To"
                        placeholder="Enter a name"
                        value={value}
                        onChange={onChange}
                        isError={invalid}
                        error={error?.message}
                      />
                    )}
                  />
                </SubmissionColumn>
                {/* Client Email */}
                <SubmissionColumn>
                  <Controller
                    control={control}
                    name="client_email"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <TextInput
                        required
                        label="Email"
                        placeholder="Enter an email"
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        isError={invalid}
                        error={error?.message}
                      />
                    )}
                  />
                </SubmissionColumn>
              </SubmissionRow>
              <SubmissionRow>
                <SubmissionColumn>
                  <Label>
                    <Caption>Phone Number</Caption>
                  </Label>
                  <SubmissionRow columnGapSize="10">
                    {/* Client Country Calling Code */}
                    <SubmissionColumn widthRatio={'4'} columnGapSize="10">
                      <Controller
                        control={control}
                        name="client_country_calling_code"
                        render={({
                          field: { onChange, value, name },
                          fieldState: { invalid, error },
                        }) => (
                          <Combobox
                            placeholder="Select a country calling code"
                            options={clientCountryCallingCodeOptionsQuery.data || []}
                            fieldName={name}
                            value={value}
                            onChange={onChange}
                            isError={invalid}
                            error={error ? 'This field is required' : ''}
                          />
                        )}
                      />
                    </SubmissionColumn>
                    {/* Client Phone Number */}
                    <SubmissionColumn widthRatio={'4'} columnGapSize="10">
                      <Controller
                        control={control}
                        name="client_phone_number"
                        render={({
                          field: { onChange, value },
                          fieldState: { invalid, error },
                        }) => (
                          <TextInput
                            placeholder="Enter a phone number"
                            value={value}
                            onChange={onChange}
                            isError={invalid}
                            error={error?.message}
                          />
                        )}
                      />
                    </SubmissionColumn>
                    {/* Client Phone Extension Number */}
                    <SubmissionColumn widthRatio={'2'} columnGapSize="10">
                      <Controller
                        control={control}
                        name="client_extension_number"
                        render={({
                          field: { onChange, value },
                          fieldState: { invalid, error },
                        }) => (
                          <TextInput
                            placeholder="Enter an extension number"
                            value={value}
                            onChange={onChange}
                            textInputContentColumnGap={0}
                            startElement={
                              <ButtonText size={14} css={{ color: '$text-secondary', ml: 6 }}>
                                ＃
                              </ButtonText>
                            }
                            isError={invalid}
                            error={error?.message}
                          />
                        )}
                      />
                    </SubmissionColumn>
                  </SubmissionRow>
                </SubmissionColumn>
              </SubmissionRow>
            </SubmissionSection>
          </SubmissionPaper>
          <Spacer axis="vertical" css={{ $$size: '80px' }} />
          {/* Wizard Bar */}
          <WizardBar
            css={{ left: navBarWidth }}
            animate={isNavBarOpen ? 'shrink' : 'grow'}
            variants={fixedLayoutGrowMotion(navBarWidth)}>
            {/* WizardBar Left*/}
            {/* Cancel */}
            <DialogAlertRoot>
              {/* Cancel Dialog Trigger */}
              <DialogAlertTrigger asChild>
                <Button
                  size="md"
                  variant="borderless"
                  color="achromatic"
                  css={{ mr: 'auto' }}
                  data-track-id="btn-dialog-trigger-cancel">
                  <ButtonText size={14} bold>
                    Cancel
                  </ButtonText>
                </Button>
              </DialogAlertTrigger>
              <DialogAlertPortal>
                <DialogAlertOverlay />
                {/* Cancel Dialog Content */}
                <DialogAlertContent
                  css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
                  <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                    {/* Cancel Dialog Header */}
                    <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                      <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                      <DialogAlertTitle asChild>
                        <Heading5 css={{ flexGrow: 1 }}>{`Leave form`}</Heading5>
                      </DialogAlertTitle>
                    </Box>
                    {/* Cancel Dialog Body */}
                    <DialogAlertDescription css={{ pl: 40 }}>
                      <Text css={{ color: '$text-secondary' }}>
                        Are you sure you want to leave this form?
                      </Text>
                    </DialogAlertDescription>
                    {/* Cancel Dialog Footer */}
                    <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                      {/* Leave */}
                      <DialogAlertCancel asChild>
                        <Button
                          variant="borderless"
                          color="achromatic"
                          size="md"
                          disabled={
                            postAttachmentMutation.isLoading ||
                            postAppMutation.isLoading ||
                            patchAppMutation.isLoading
                          }
                          onClick={() => onLeaveClick()}
                          data-track-id="btn-leave">
                          <ButtonText size={14} bold>
                            Leave
                          </ButtonText>
                        </Button>
                      </DialogAlertCancel>
                      {/* Save */}
                      {getValues('status') === 'rejected' ? null : (
                        <Button
                          variant="outlined"
                          size="md"
                          onClick={() => onSaveClick()}
                          isLoading={
                            postAttachmentMutation.isLoading ||
                            postAppMutation.isLoading ||
                            patchAppMutation.isLoading
                          }
                          disabled={
                            postAttachmentMutation.isLoading ||
                            postAppMutation.isLoading ||
                            patchAppMutation.isLoading
                          }
                          css={{ width: appId ? 67 : 123 }}
                          data-track-id="btn-save">
                          <ButtonText size={14} bold>
                            {appId ? 'Save' : 'Save as draft'}
                          </ButtonText>
                        </Button>
                      )}
                      {/* Stay */}
                      <DialogAlertCancel asChild>
                        <Button
                          size="md"
                          disabled={
                            postAttachmentMutation.isLoading ||
                            postAppMutation.isLoading ||
                            patchAppMutation.isLoading
                          }
                          data-track-id="btn-stay">
                          <ButtonText size={14} bold>
                            Stay on this form
                          </ButtonText>
                        </Button>
                      </DialogAlertCancel>
                    </Box>
                  </Box>
                </DialogAlertContent>
              </DialogAlertPortal>
            </DialogAlertRoot>
            {/* WizardBar Right */}
            <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
              <DialogRoot>
                {/* Back */}
                <DialogTrigger asChild>
                  <Button size="md" variant="outlined" data-track-id="btn-dialog-trigger-back">
                    <ButtonText size={14} bold>
                      Back
                    </ButtonText>
                  </Button>
                </DialogTrigger>
                <DialogPortal>
                  <DialogOverlay />
                  <DialogContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
                    <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                      {/* Dialog Header */}
                      <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                        <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                        <DialogTitle asChild>
                          <Heading5 css={{ flexGrow: 1 }}>{`Leave form`}</Heading5>
                        </DialogTitle>
                        <DialogClose asChild>
                          <IconButton iconName="close" size="small" isIconBold={true} />
                        </DialogClose>
                      </Box>
                      {/* Dialog Body */}
                      <DialogDescription css={{ pl: 40 }}>
                        <Text css={{ color: '$text-secondary' }}>
                          Back now will discard any edits you made on this form.
                        </Text>
                      </DialogDescription>
                      {/* Dialog Footer */}
                      <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                        {/* Back */}
                        <DialogClose asChild>
                          <Button
                            variant="borderless"
                            color="achromatic"
                            size="md"
                            onClick={() => onBackClick()}
                            data-track-id="btn-back">
                            <ButtonText size={14} bold>
                              Back
                            </ButtonText>
                          </Button>
                        </DialogClose>
                        {/* Stay */}
                        <DialogClose asChild>
                          <Button size="md">
                            <ButtonText size={14} bold>
                              Stay on this form
                            </ButtonText>
                          </Button>
                        </DialogClose>
                      </Box>
                    </Box>
                  </DialogContent>
                </DialogPortal>
              </DialogRoot>
              {/* Next */}
              <Button data-track-id="btn-next" type="submit" size="md">
                <ButtonText size={14} bold>
                  Next
                </ButtonText>
              </Button>
            </Box>
          </WizardBar>
        </form>
      )}
    </>
  );
};

export default GeneralInfo;
