import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getProductChipColor } from '../../../../utils/get-variant';
import {
  useGetAppActionBar,
  usePostApprovalRequest,
  usePostSealRequest,
  usePatchWithdraw,
  usePostCopy,
} from '../../../../services/application/verified-standard/app-actions.api';
import { useDeleteStdApp } from '../../../../services/application/verified-standard/app.api';
import useGetAppDocBar from '../../../../services/application/verified-standard/app-doc-bar.api';
import { AppDocBarEntity } from '../../../../types/application/verified/app-doc-bar';
import { ProductType } from '../../../../types/application/constants';
import { AppDetailDocStatus, AppDetailTabs } from '../../../../types/constants';
import { appDetailPanels, appDetailTabs } from '../../../../store/application/constants';
import ApprovalTab from './approval-tab';
import ContractRequestTab from './contract-request-tab';
import SealRequestTab from './seal-request-tab';
import DocumentTab from './document-tab';
import InfoPanel from './info-panel';
import ApprovalPanel from './approval-panel';
import CommentPanel from './comment-panel';
import { elementGrowMotion } from '../../../../styles/motions';
import {
  curAppDetailPanelAtom,
  curAppDetailTabAtom,
  isAppDetailPanelsOpenAtom,
  isNavBarOpenAtom,
  navBarWidthAtom,
} from '../../../../store/atoms';
import ActionBar from '../../../../components/bar/action-bar';
import Spinner from '../../../../components/helper/spinner';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import Box from '../../../../components/box';
import { Heading5 } from '../../../../components/typography/heading';
import IconButton from '../../../../components/button/icon-button';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../../../../components/helper/tool-tip';
import MSymbol from '../../../../components/icon/m-symbol';
import { Text, ButtonText } from '../../../../components/typography/text';
import Button from '../../../../components/button/button';
import { DocBarContent, DocBarItemTrigger, DocBarRoot } from '../../../../components/bar/doc-bar';
import Spacer from '../../../../components/surface/spacer';
import DocIcon from '../../../../components/icon/doc-icon';
import { CenteredBoxFlex } from '../../../../components/surface/centered-box';
import AppDetailContent from '../../../../components/surface/app-detail-content';
import {
  AppDetailTabsContainer,
  AppDetailTabsContent,
  AppDetailTabsList,
  AppDetailTabsRoot,
  AppDetailTabsTrigger,
} from '../../../../components/surface/app-detail-tabs';
import {
  AppDetailPanelsContent,
  AppDetailPanelsList,
  AppDetailPanelsRoot,
  AppDetailPanelsToggler,
  AppDetailPanelsTogglerArrow,
  AppDetailPanelsTrigger,
} from '../../../../components/surface/app-detail-panels';
import Divider from '../../../../components/surface/divider';

const Detail = () => {
  const { appType, product, clientType, appId } = useParams();
  const navigate = useNavigate();
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const [curAppDetailTab, setCurAppDetailTab] = useAtom(curAppDetailTabAtom);
  const [curDoc, setCurDoc] = useState<AppDocBarEntity>();
  const [curAppDetailPanel, setCurAppDetailPanel] = useAtom(curAppDetailPanelAtom);
  const [isAppDetailPanelsOpen, setIsAppDetailPanelsOpen] = useAtom(isAppDetailPanelsOpenAtom);

  const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isApprovalRequestDialogOpen, setIsApprovalRequestDialogOpen] = useState(false);
  const [isSealRequestDialogOpen, setIsSealRequestDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);

  const appActionBarQuery = useGetAppActionBar({ appId: appId || '' });
  const appDocBarQuery = useGetAppDocBar({
    appId: appId || '',
    isDraft: appActionBarQuery.isSuccess ? appActionBarQuery.data.status === 'draft' : true,
  });
  const deleteMutation = useDeleteStdApp({ appId: appId || '' });
  const withdrawMutation = usePatchWithdraw({ appId: appId || '' });
  const copyMutation = usePostCopy({ appId: appId || '' });
  const approvalRequestMutation = usePostApprovalRequest({
    appId: appId || '',
    product: product as ProductType,
  });
  const sealRequestMutation = usePostSealRequest({
    appId: appId || '',
    product: product as ProductType,
  });

  const onWithdrawClick = () => {
    withdrawMutation.mutate();
  };
  const onEditClick = () => {
    navigate(
      `/applications/edit/${appType}/${product}/${clientType}/${appId}?app_status=${
        appActionBarQuery.data?.status || ''
      }`
    );
  };
  const onApprovalRequestClick = () => {
    approvalRequestMutation.mutate();
  };
  const onSealRequestClick = () => {
    sealRequestMutation.mutate();
  };
  const onAppDetailTabChange = (newValue: AppDetailTabs) => {
    setCurAppDetailTab(newValue);
  };
  const onDeleteClick = () => {
    deleteMutation.mutate();
  };
  const onCopyClick = () => {
    copyMutation.mutate();
  };
  const onDocBarItemClick = (doc: AppDocBarEntity) => {
    setCurDoc(doc);
    setCurAppDetailTab('document');
  };

  useEffect(() => {
    if (appDocBarQuery.isSuccess && appDocBarQuery.data.length > 0) {
      setCurDoc(appDocBarQuery.data[0]);
    }
  }, [appDocBarQuery.data, appDocBarQuery.isSuccess]);

  return (
    <>
      {/* Action Bar */}
      <ActionBar
        as={motion.div}
        initial={isNavBarOpen ? 'shrink' : 'grow'}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={elementGrowMotion(navBarWidth)}>
        {appActionBarQuery.isLoading ? (
          <Spinner />
        ) : appActionBarQuery.isSuccess ? (
          <>
            {/* Product Chip */}
            <ColorfulChip
              text={`${product === 'finops' ? 'FinOps' : product?.toUpperCase()}`}
              color={getProductChipColor({ product: product || '' })}
              variant="solid"
            />
            {/* App Name */}
            <Box css={{ display: 'flex', flexGrow: 1, minWidth: 0, ml: 20, mr: 10 }}>
              <Heading5
                css={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>{`${appActionBarQuery.data.name}`}</Heading5>
            </Box>
            {/* Action Buttons */}
            <Box css={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
              {appActionBarQuery.data.action_map.show_withdraw ? (
                <DialogAlertRoot
                  open={isWithdrawDialogOpen}
                  onOpenChange={(open) => setIsWithdrawDialogOpen(open)}>
                  {/* Withdraw */}
                  <DialogAlertTrigger asChild>
                    <Button
                      size="md"
                      variant="outlined"
                      color="error"
                      startElement={
                        <MSymbol
                          iconName="undo"
                          weight={700}
                          size={16}
                          css={{ color: 'inherit' }}
                        />
                      }>
                      <ButtonText size={14} bold>
                        Withdraw
                      </ButtonText>
                    </Button>
                  </DialogAlertTrigger>
                  <DialogAlertPortal>
                    <DialogAlertOverlay />
                    <DialogAlertContent
                      css={{
                        px: 30,
                        py: 20,
                        borderRadius: '$10',
                        maxWidth: '655px',
                      }}>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 30,
                        }}>
                        {/* Withdraw Dialog Title, Dialog Close Button */}
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 15,
                          }}>
                          <MSymbol
                            iconName="error"
                            weight={700}
                            size={30}
                            css={{ color: '$error60' }}
                          />
                          <DialogAlertTitle asChild>
                            <Heading5
                              css={{
                                flexGrow: 1,
                              }}>{`Withdraw`}</Heading5>
                          </DialogAlertTitle>
                        </Box>
                        {/* Dialog Description */}
                        <DialogAlertDescription css={{ px: 40 }}>
                          <Text>
                            {`Are you sure you want to`}{' '}
                            <Text css={{ display: 'inline-block', color: '$error60' }}>
                              withdraw
                            </Text>{' '}
                            {`this application ?`}
                          </Text>
                        </DialogAlertDescription>
                        {/* Dialog Action */}
                        <Box
                          css={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            columnGap: 20,
                          }}>
                          <Button
                            size="md"
                            color="error"
                            spinnerColor="error"
                            onClick={onWithdrawClick}
                            isLoading={withdrawMutation.isLoading}
                            disabled={withdrawMutation.isLoading}
                            css={{ width: 99 }}>
                            <ButtonText size={'14'} bold>
                              Withdraw
                            </ButtonText>
                          </Button>
                          <DialogAlertCancel asChild>
                            <Button
                              size="md"
                              variant="borderless"
                              color="achromatic"
                              disabled={withdrawMutation.isLoading}>
                              <ButtonText size={'14'} bold>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                        </Box>
                      </Box>
                    </DialogAlertContent>
                  </DialogAlertPortal>
                </DialogAlertRoot>
              ) : null}
              {appActionBarQuery.data.action_map.show_edit ? (
                <DialogAlertRoot
                  open={isEditDialogOpen}
                  onOpenChange={(open) => setIsEditDialogOpen(open)}>
                  {/* Edit */}
                  <DialogAlertTrigger asChild>
                    <Button
                      size="md"
                      variant="outlined"
                      startElement={
                        <MSymbol
                          iconName="edit"
                          weight={700}
                          size={16}
                          fill={false}
                          css={{ color: 'inherit' }}
                        />
                      }>
                      <ButtonText size={14} bold>
                        Edit
                      </ButtonText>
                    </Button>
                  </DialogAlertTrigger>
                  <DialogAlertPortal>
                    <DialogAlertOverlay />
                    <DialogAlertContent
                      css={{
                        px: 30,
                        py: 20,
                        borderRadius: '$10',
                        maxWidth: '655px',
                      }}>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 30,
                        }}>
                        {/* Edit Dialog Title, Dialog Close Button */}
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 15,
                          }}>
                          <MSymbol
                            iconName="error"
                            weight={700}
                            size={30}
                            css={{ color: '$error60' }}
                          />
                          <DialogAlertTitle asChild>
                            <Heading5
                              css={{
                                flexGrow: 1,
                              }}>{`Edit`}</Heading5>
                          </DialogAlertTitle>
                        </Box>
                        {/* Dialog Description */}
                        <DialogAlertDescription css={{ px: 40 }}>
                          <Text>
                            {`Are you sure you want to`}{' '}
                            <Text css={{ display: 'inline-block', color: '$primary50' }}>edit</Text>{' '}
                            {`this application ?`}
                          </Text>
                        </DialogAlertDescription>
                        {/* Dialog Action */}
                        <Box
                          css={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            columnGap: 20,
                          }}>
                          <Button
                            size="md"
                            color="primary"
                            spinnerColor="primary"
                            onClick={onEditClick}>
                            <ButtonText size={'14'} bold>
                              Start edit
                            </ButtonText>
                          </Button>
                          <DialogAlertCancel asChild>
                            <Button size="md" variant="borderless" color="achromatic">
                              <ButtonText size={'14'} bold>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                        </Box>
                      </Box>
                    </DialogAlertContent>
                  </DialogAlertPortal>
                </DialogAlertRoot>
              ) : null}
              {appActionBarQuery.data.action_map.show_approval_request ? (
                <DialogAlertRoot
                  open={isApprovalRequestDialogOpen}
                  onOpenChange={(open) => setIsApprovalRequestDialogOpen(open)}>
                  {/* Approval Request */}
                  <DialogAlertTrigger asChild>
                    <Button
                      size="md"
                      variant="outlined"
                      startElement={
                        <MSymbol
                          iconName="how_to_reg"
                          weight={700}
                          size={16}
                          fill={true}
                          css={{ color: 'inherit' }}
                        />
                      }>
                      <ButtonText size={14} bold css={{ whiteSpace: 'nowrap' }}>
                        Approval Request
                      </ButtonText>
                    </Button>
                  </DialogAlertTrigger>
                  <DialogAlertPortal>
                    <DialogAlertOverlay />
                    <DialogAlertContent
                      css={{
                        px: 30,
                        py: 20,
                        borderRadius: '$10',
                        maxWidth: '655px',
                      }}>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 30,
                        }}>
                        {/* Approval Request Dialog Title, Dialog Close Button */}
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 15,
                          }}>
                          <MSymbol
                            iconName="error"
                            weight={700}
                            size={30}
                            css={{ color: '$error60' }}
                          />
                          <DialogAlertTitle asChild>
                            <Heading5
                              css={{
                                flexGrow: 1,
                              }}>{`Approval Request`}</Heading5>
                          </DialogAlertTitle>
                        </Box>
                        {/* Dialog Description */}
                        <DialogAlertDescription css={{ px: 40 }}>
                          <Text>
                            {`Once the application is applied for`}{' '}
                            <Text css={{ display: 'inline-block', color: '$primary50' }}>
                              approval request
                            </Text>{' '}
                            {`, it cannot be revised again. Are you sure you want to proceed?`}
                          </Text>
                        </DialogAlertDescription>
                        {/* Dialog Action */}
                        <Box
                          css={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            columnGap: 20,
                          }}>
                          <Button
                            size="md"
                            color="primary"
                            spinnerColor="primary"
                            isLoading={approvalRequestMutation.isLoading}
                            disabled={approvalRequestMutation.isLoading}
                            css={{ width: 153 }}
                            onClick={onApprovalRequestClick}>
                            <ButtonText size={'14'} bold>
                              Approval Request
                            </ButtonText>
                          </Button>
                          <DialogAlertCancel asChild>
                            <Button
                              size="md"
                              variant="borderless"
                              color="achromatic"
                              disabled={approvalRequestMutation.isLoading}>
                              <ButtonText size={'14'} bold>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                        </Box>
                      </Box>
                    </DialogAlertContent>
                  </DialogAlertPortal>
                </DialogAlertRoot>
              ) : null}
              {appActionBarQuery.data.action_map.show_seal_request ? (
                <DialogAlertRoot
                  open={isSealRequestDialogOpen}
                  onOpenChange={(open) => setIsSealRequestDialogOpen(open)}>
                  {/* Seal Request */}
                  <DialogAlertTrigger asChild>
                    <Button
                      size="md"
                      variant="outlined"
                      startElement={
                        <MSymbol
                          iconName="approval"
                          weight={700}
                          size={16}
                          fill={false}
                          css={{ color: 'inherit' }}
                        />
                      }>
                      <ButtonText size={14} bold css={{ whiteSpace: 'nowrap' }}>
                        Seal Request
                      </ButtonText>
                    </Button>
                  </DialogAlertTrigger>
                  <DialogAlertPortal>
                    <DialogAlertOverlay />
                    <DialogAlertContent
                      css={{
                        px: 30,
                        py: 20,
                        borderRadius: '$10',
                        maxWidth: '655px',
                      }}>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 30,
                        }}>
                        {/* Seal Request Dialog Title, Dialog Close Button */}
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 15,
                          }}>
                          <MSymbol
                            iconName="error"
                            weight={700}
                            size={30}
                            css={{ color: '$error60' }}
                          />
                          <DialogAlertTitle asChild>
                            <Heading5
                              css={{
                                flexGrow: 1,
                              }}>{`Seal Request`}</Heading5>
                          </DialogAlertTitle>
                        </Box>
                        {/* Dialog Description */}
                        <DialogAlertDescription css={{ px: 40 }}>
                          <Text>
                            {`Once the application is applied for`}{' '}
                            <Text css={{ display: 'inline-block', color: '$primary50' }}>
                              seal request
                            </Text>{' '}
                            {`, it cannot be revised again. Are you sure you want to proceed?`}
                          </Text>
                        </DialogAlertDescription>
                        {/* Dialog Action */}
                        <Box
                          css={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            columnGap: 20,
                          }}>
                          <Button
                            size="md"
                            color="primary"
                            spinnerColor="primary"
                            isLoading={sealRequestMutation.isLoading}
                            disabled={sealRequestMutation.isLoading}
                            css={{ width: 121 }}
                            onClick={onSealRequestClick}>
                            <ButtonText size={'14'} bold>
                              Seal Request
                            </ButtonText>
                          </Button>
                          <DialogAlertCancel asChild>
                            <Button
                              size="md"
                              variant="borderless"
                              color="achromatic"
                              disabled={sealRequestMutation.isLoading}>
                              <ButtonText size={'14'} bold>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                        </Box>
                      </Box>
                    </DialogAlertContent>
                  </DialogAlertPortal>
                </DialogAlertRoot>
              ) : null}
              {/* {appActionBarQuery.data.action_map.show_generate_document ? (
                <Button
                  size="md"
                  variant="solid"
                  startElement={
                    <MSymbol
                      iconName="contract"
                      weight={700}
                      size={16}
                      fill={false}
                      css={{ color: 'inherit' }}
                    />
                  }>
                  <ButtonText size={14} bold css={{ whiteSpace: 'nowrap' }}>
                    Regenerate documents
                  </ButtonText>
                </Button>
              ) : null} */}
              {/* {appActionBarQuery.data.action_map.show_check_n_sign_document ? (
                <Button
                  size="md"
                  variant="solid"
                  startElement={
                    <MSymbol
                      iconName="approval"
                      weight={700}
                      size={16}
                      fill={true}
                      css={{ color: 'inherit' }}
                    />
                  }>
                  <ButtonText size={14} bold css={{ whiteSpace: 'nowrap' }}>
                    Check and sign documents
                  </ButtonText>
                </Button>
              ) : null} */}
              {appActionBarQuery.data.action_map.show_copy ? (
                <DialogAlertRoot
                  open={isCopyDialogOpen}
                  onOpenChange={(open) => setIsCopyDialogOpen(open)}>
                  {/* Copy App */}
                  <TooltipRoot>
                    <TooltipTrigger asChild>
                      <DialogAlertTrigger asChild>
                        <IconButton
                          color="secondary"
                          iconName="content_copy"
                          isIconFill={false}
                          size="small"
                        />
                      </DialogAlertTrigger>
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent
                        sideOffset={5}
                        side={`top`}
                        css={{
                          px: 16,
                          py: 4.5,
                        }}>
                        <Text css={{ color: '$white' }}>Copy</Text>
                        <TooltipArrow />
                      </TooltipContent>
                    </TooltipPortal>
                  </TooltipRoot>
                  <DialogAlertPortal>
                    <DialogAlertOverlay />
                    <DialogAlertContent
                      css={{
                        px: 30,
                        py: 20,
                        borderRadius: '$10',
                        maxWidth: '655px',
                      }}>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 30,
                        }}>
                        {/* Copy App Dialog Title, Dialog Close Button */}
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 15,
                          }}>
                          <MSymbol
                            iconName="error"
                            weight={700}
                            size={30}
                            css={{ color: '$error60' }}
                          />
                          <DialogAlertTitle asChild>
                            <Heading5
                              css={{
                                flexGrow: 1,
                              }}>{`Copy application`}</Heading5>
                          </DialogAlertTitle>
                        </Box>
                        {/* Dialog Description */}
                        <DialogAlertDescription css={{ px: 40 }}>
                          <Text>Are you sure you want to copy the application ?</Text>
                        </DialogAlertDescription>
                        {/* Dialog Action */}
                        <Box
                          css={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            columnGap: 20,
                          }}>
                          <Button
                            size="md"
                            color="primary"
                            spinnerColor="primary"
                            isLoading={copyMutation.isLoading}
                            disabled={copyMutation.isLoading}
                            css={{ width: 75 }}
                            onClick={onCopyClick}>
                            <ButtonText size={'14'} bold>
                              Copy
                            </ButtonText>
                          </Button>
                          <DialogAlertCancel asChild>
                            <Button
                              size="md"
                              variant="borderless"
                              color="achromatic"
                              disabled={copyMutation.isLoading}>
                              <ButtonText size={'14'} bold>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                        </Box>
                      </Box>
                    </DialogAlertContent>
                  </DialogAlertPortal>
                </DialogAlertRoot>
              ) : null}
              {appActionBarQuery.data.action_map.show_delete ? (
                <DialogAlertRoot
                  open={isDeleteDialogOpen}
                  onOpenChange={(open) => setIsDeleteDialogOpen(open)}>
                  {/* Delete App */}
                  <TooltipRoot>
                    <TooltipTrigger asChild>
                      <DialogAlertTrigger asChild>
                        <IconButton
                          color="secondary"
                          iconName="delete"
                          isIconFill={false}
                          size="small"
                        />
                      </DialogAlertTrigger>
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent
                        sideOffset={5}
                        side={`top`}
                        css={{
                          px: 16,
                          py: 4.5,
                        }}>
                        <Text css={{ color: '$white' }}>Delete</Text>
                        <TooltipArrow />
                      </TooltipContent>
                    </TooltipPortal>
                  </TooltipRoot>
                  <DialogAlertPortal>
                    <DialogAlertOverlay />
                    <DialogAlertContent
                      css={{
                        px: 30,
                        py: 20,
                        borderRadius: '$10',
                        maxWidth: '655px',
                      }}>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 30,
                        }}>
                        {/* Delete App Dialog Title, Dialog Close Button */}
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 15,
                          }}>
                          <MSymbol
                            iconName="error"
                            weight={700}
                            size={30}
                            css={{ color: '$error60' }}
                          />
                          <DialogAlertTitle asChild>
                            <Heading5
                              css={{
                                flexGrow: 1,
                              }}>{`Delete application`}</Heading5>
                          </DialogAlertTitle>
                        </Box>
                        {/* Dialog Description */}
                        <DialogAlertDescription css={{ px: 40 }}>
                          <Text>
                            Are you sure you want to permanently delete the application, its
                            comments and attachments, and all of its data?
                          </Text>
                        </DialogAlertDescription>
                        {/* Dialog Action */}
                        <Box
                          css={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            columnGap: 20,
                          }}>
                          <Button
                            size="md"
                            color="error"
                            spinnerColor="error"
                            isLoading={deleteMutation.isLoading}
                            disabled={deleteMutation.isLoading}
                            css={{ width: 75 }}
                            onClick={onDeleteClick}>
                            <ButtonText size={'14'} bold>
                              Delete
                            </ButtonText>
                          </Button>
                          <DialogAlertCancel asChild>
                            <Button
                              size="md"
                              variant="borderless"
                              color="achromatic"
                              disabled={deleteMutation.isLoading}>
                              <ButtonText size={'14'} bold>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                        </Box>
                      </Box>
                    </DialogAlertContent>
                  </DialogAlertPortal>
                </DialogAlertRoot>
              ) : null}
              {/* {appActionBarQuery.data.action_map.show_download ? (
                <IconButton color="secondary" iconName="download" isIconFill={false} size="small" />
              ) : null} */}
            </Box>
          </>
        ) : appActionBarQuery.isError ? (
          <Heading5>Something went wrong</Heading5>
        ) : null}
      </ActionBar>
      {/* Doc Bar */}
      <DocBarRoot>
        <Spacer axis={'vertical'} css={{ $$size: '57px' }} />
        {/* Doc Bar */}
        {appDocBarQuery.isLoading ? (
          <Spinner />
        ) : appDocBarQuery.isSuccess ? (
          appDocBarQuery.data.length > 0 ? (
            <DocBarContent>
              {appDocBarQuery.data.map((doc) => (
                <DocBarItemTrigger
                  key={doc.id}
                  data-state={curDoc && curDoc.id === doc.id ? 'active' : 'inactive'}
                  onClick={() => onDocBarItemClick(doc)}>
                  <TooltipRoot>
                    <TooltipTrigger asChild>
                      <Box css={{ width: 36, height: 36, flexShrink: 0 }}>
                        <DocIcon
                          docType={doc.icon_map.doc_type}
                          docIconBGColor={doc.icon_map.doc_icon_bg}
                          docIconStatus={doc.icon_map.doc_icon_status}
                        />
                      </Box>
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent
                        sideOffset={15}
                        side={`right`}
                        css={{
                          px: 16,
                          py: 4.5,
                          display: doc.doc_name ? 'revert' : 'none',
                        }}>
                        <Text css={{ color: '$white' }}>{`${doc.doc_name}`}</Text>
                        <TooltipArrow />
                      </TooltipContent>
                    </TooltipPortal>
                  </TooltipRoot>
                </DocBarItemTrigger>
              ))}
            </DocBarContent>
          ) : (
            <Text>-</Text>
          )
        ) : appDocBarQuery.isError ? (
          <Text>Err</Text>
        ) : (
          <>
            <CenteredBoxFlex css={{ width: 50, height: 50 }}>
              <Box css={{ width: 36, height: 36, cursor: 'not-allowed' }}>
                <DocIcon docType={'quotation'} docIconBGColor={'disabled'} docIconStatus={''} />
              </Box>
            </CenteredBoxFlex>
            <CenteredBoxFlex css={{ width: 50, height: 50 }}>
              <Box css={{ width: 36, height: 36, cursor: 'not-allowed' }}>
                <DocIcon docType={'contract'} docIconBGColor={'disabled'} docIconStatus={''} />
              </Box>
            </CenteredBoxFlex>
          </>
        )}
      </DocBarRoot>
      {/* Detail Tabs */}
      <AppDetailContent>
        <AppDetailTabsContainer>
          <AppDetailTabsRoot
            value={curAppDetailTab}
            onValueChange={(newValue) => onAppDetailTabChange(newValue as AppDetailTabs)}>
            <AppDetailTabsList aria-label="verified-gcp-std-detail-tab">
              {appDetailTabs.map((appDetailTab) => (
                <AppDetailTabsTrigger key={appDetailTab.id} value={appDetailTab.value as string}>
                  <Box
                    css={{
                      display: 'flex',
                      height: 25,
                      alignItems: 'center',
                      columnGap: 5,
                    }}>
                    <MSymbol
                      iconName={`${appDetailTab.other_info?.iconName}`}
                      css={{ color: 'inherit', fontSize: '16px !important' }}
                    />
                    {`${appDetailTab.name}`}
                  </Box>
                </AppDetailTabsTrigger>
              ))}
            </AppDetailTabsList>
            <AppDetailTabsContent value={curAppDetailTab} size={'fill'}>
              {(() => {
                switch (curAppDetailTab) {
                  // * Approval Tab
                  case 'approval':
                    return (
                      <ApprovalTab
                        appId={appId || ''}
                        appStatus={appActionBarQuery.data?.status || ''}
                      />
                    );
                  // * Contract Request Tab (Old name: Submission Tab)
                  case 'submission':
                    return (
                      <ContractRequestTab
                        appId={appId || ''}
                        product={(product as ProductType) || ''}
                      />
                    );
                  // * Seal Request Tab
                  case 'seal':
                    return (
                      <SealRequestTab
                        appId={appId || ''}
                        product={(product as ProductType) || ''}
                      />
                    );
                  // * Document Tab
                  case 'document':
                    return (
                      <DocumentTab
                        appStatus={appActionBarQuery.data?.status || ''}
                        docStatus={(curDoc?.doc_status as AppDetailDocStatus) || ''}
                        docUrl={curDoc?.doc_url || ''}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </AppDetailTabsContent>
          </AppDetailTabsRoot>
          <Spacer axis={'vertical'} css={{ $$size: '18px' }} />
        </AppDetailTabsContainer>
      </AppDetailContent>
      {/* Detail Panels */}
      <AppDetailPanelsRoot open={isAppDetailPanelsOpen}>
        <AppDetailPanelsList
          open={isAppDetailPanelsOpen}
          onOpenChange={(open) => setIsAppDetailPanelsOpen(open)}>
          <AppDetailPanelsToggler
            onClick={() => {
              setIsAppDetailPanelsOpen(!isAppDetailPanelsOpen);
            }}>
            <AppDetailPanelsTogglerArrow iconName="navigate_next" open={isAppDetailPanelsOpen} />
          </AppDetailPanelsToggler>
          <Box>
            {appDetailPanels.map((panelTrigger) => (
              <Box key={panelTrigger.id}>
                {panelTrigger.value === 'approval' ? (
                  <Divider
                    axis={'horizontal'}
                    css={{ $$size: '30px', backgroundColor: '$secondary40', m: '10px auto' }}
                  />
                ) : null}
                <TooltipRoot key={panelTrigger.id}>
                  <TooltipTrigger asChild>
                    <div>
                      <AppDetailPanelsTrigger
                        triggerIconName={panelTrigger.other_info?.iconName as string}
                        light={curAppDetailPanel === panelTrigger.value ? 'on' : 'off'}
                        onClick={() => setCurAppDetailPanel(panelTrigger.value)}
                      />
                    </div>
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent
                      sideOffset={5}
                      side={`left`}
                      css={{
                        px: 16,
                        py: 4.5,
                      }}>
                      <Text css={{ color: '$white' }}>{`${panelTrigger.name ?? '-'}`}</Text>
                      <TooltipArrow />
                    </TooltipContent>
                  </TooltipPortal>
                </TooltipRoot>
              </Box>
            ))}
          </Box>
        </AppDetailPanelsList>
        <AppDetailPanelsContent>
          {(() => {
            switch (curAppDetailPanel) {
              case 'info':
                return <InfoPanel appId={appId || ''} />;
              case 'activity':
                return <>activity</>;
              case 'approval':
                return <ApprovalPanel appId={appId || ''} />;
              case 'attachment':
                return <>attachment</>;
              case 'comment':
                return <CommentPanel appId={appId || ''} />;
              default:
                return null;
            }
          })()}
        </AppDetailPanelsContent>
      </AppDetailPanelsRoot>
    </>
  );
};

export default Detail;
