import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { cmCustomerTypes, cmProducts } from '../../../../store/application/constants';
import { PostFinopsStdAppPayload } from '../../../../types/application/constants';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../store/atoms';
import {
  curSubFormStepAtom,
  postFinopsStdAppPayloadAtom,
  postStdAppAttPayloadAtom,
} from '../../../../store/application/atoms';
import {
  usePatchStdApp,
  usePostStdApp,
} from '../../../../services/application/verified-standard/app.api';
import { usePostStdAppAtt } from '../../../../services/application/verified-standard/attachment.api';
import {
  useGetMonthOptions,
  useGetFinopsServicePlanOptions,
} from '../../../../services/application/verified-standard/dropdown.api';
import { scrollToFirstError, scrollToTop } from '../../../../utils/scroll';
import Box from '../../../../components/box';
import { ButtonText, Text } from '../../../../components/typography/text';
import { Heading5 } from '../../../../components/typography/heading';
import Spacer from '../../../../components/surface/spacer';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import RadioGroup from '../../../../components/field/radio-group';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import Button from '../../../../components/button/button';
import MSymbol from '../../../../components/icon/m-symbol';
import Combobox from '../../../../components/dropdown/combobox';
import WizardBar from '../../../../components/bar/wizard-bar';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionPaperHeader,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import Combofield from '../../../../components/dropdown/combofield';
import ServiceItemButton from '../../../../components/button/service-item-button';
import { fixedLayoutGrowMotion } from '../../../../styles/motions';
import LogoLumiture from '../../../../assets/images/logo-lumiture.svg';

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({
  automatically_renew_term: Yup.number()
    .typeError('Must specify a number')
    .moreThan(-1, 'Must greater or equal to 0')
    .integer('Must be an integer')
    .required('This field is required'),
  service_plan: Yup.object().shape({
    value: Yup.string().required(),
  }),
  customer_type: Yup.string().required('This field is required'),
});

const ServiceInfo = () => {
  const navigate = useNavigate();
  const { appId } = useParams();
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const setCurSubFormStep = useSetAtom(curSubFormStepAtom);
  const [postStdAppPayload, setPostStdAppPayload] = useAtom(postFinopsStdAppPayloadAtom);
  const postStdAppAttPayload = useAtomValue(postStdAppAttPayloadAtom);

  const { control, handleSubmit, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: postStdAppPayload,
    resolver: yupResolver(validationSchema),
  });

  const autoRenewTermOptionsQuery = useGetMonthOptions({ field: 'finops_auto_renew_term' });
  const servicePlanOptionsQuery = useGetFinopsServicePlanOptions();
  const postAppMutation = usePostStdApp();
  const patchAppMutation = usePatchStdApp({ appId: appId || '' });
  const postAttachmentMutation = usePostStdAppAtt({
    appId,
    onSuccess: (res, appStatus) => {
      if (appId) {
        patchAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
      } else {
        if (appStatus === 'draft') {
          postAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
        } else {
          postAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
        }
      }
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const onBackClick = () => {
    setPostStdAppPayload((prev) => ({ ...prev, ...getValues() }));
    setCurSubFormStep(1);
  };
  const onNextClick: SubmitHandler<PostFinopsStdAppPayload> = (data) => {
    setPostStdAppPayload((prev) => ({ ...prev, ...data }));
    setCurSubFormStep(3);
  };
  const onNextError = (errors: unknown) => {
    scrollToFirstError(errors);
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({
      ...postStdAppAttPayload,
      appStatus: appId ? getValues('status') : 'draft',
    });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <form onSubmit={handleSubmit(onNextClick, onNextError)}>
      {/* Service */}
      <SubmissionPaper>
        {/* Header */}
        <SubmissionPaperHeader>
          <Heading5>Service</Heading5>
          <ColorfulChip text="FinOps" color="turquoise" variant="solid" />
        </SubmissionPaperHeader>
        {/* Payment Details */}
        <SubmissionSection>
          <SubmissionSectionHeader>
            <Text semibold>Payment Details</Text>
          </SubmissionSectionHeader>
          <ServiceItemButton type="button" data-state="active" showcase>
            <Box as="img" src={LogoLumiture} css={{ py: 14 }} />
          </ServiceItemButton>
          <SubmissionRow>
            {/* Product */}
            <SubmissionColumn>
              <Combobox
                required
                label="Product"
                placeholder="Select a product"
                options={cmProducts}
                value={cmProducts[2]}
                onChange={() => {}}
                disabled
              />
            </SubmissionColumn>
            {/* Service Plan */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="service_plan"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Service Plan"
                    placeholder="Select a service plan"
                    isLoading={servicePlanOptionsQuery.isLoading}
                    options={servicePlanOptionsQuery.data || []}
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Automatically Renew Term */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="automatically_renew_term"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <Combofield
                    required
                    label="Automatically Renew Term"
                    placeholder="Enter an automatically renew term"
                    isLoading={autoRenewTermOptionsQuery.isLoading}
                    options={autoRenewTermOptionsQuery.data || []}
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error?.message}
                    triggerEndElement={
                      <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                        Months
                      </ButtonText>
                    }
                  />
                )}
              />
            </SubmissionColumn>
            {/* Has this customer ever signed a FinOps quotation ? */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="customer_type"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    required
                    label="Has this customer ever signed a FinOps quotation ?"
                    value={value}
                    options={cmCustomerTypes}
                    onChange={onChange}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
        </SubmissionSection>
      </SubmissionPaper>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button
              size="md"
              variant="borderless"
              color="achromatic"
              css={{ mr: 'auto' }}
              data-track-id="btn-dialog-trigger-cancel">
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this page?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      onClick={() => onLeaveClick()}
                      data-track-id="btn-leave">
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  {getValues('status') === 'rejected' ? null : (
                    <Button
                      variant="outlined"
                      size="md"
                      onClick={() => onSaveClick()}
                      isLoading={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      css={{ width: appId ? 67 : 123 }}
                      data-track-id="btn-save">
                      <ButtonText size={14} bold>
                        {appId ? 'Save' : 'Save as draft'}
                      </ButtonText>
                    </Button>
                  )}
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      data-track-id="btn-stay">
                      <ButtonText size={14} bold>
                        Stay on this page
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button
            size="md"
            variant="outlined"
            onClick={() => onBackClick()}
            data-track-id="btn-back">
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Next */}
          <Button type="submit" size="md" data-track-id="btn-next">
            <ButtonText size={14} bold>
              Next
            </ButtonText>
          </Button>
        </Box>
      </WizardBar>
    </form>
  );
};

export default ServiceInfo;
