import { useNavigate, useParams } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { PostFinopsStdAppPayload } from '../../../../types/application/constants';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../store/atoms';
import {
  curSubFormStepAtom,
  postStdAppAttPayloadAtom,
  postFinopsStdAppPayloadAtom,
} from '../../../../store/application/atoms';
import {
  usePatchStdApp,
  usePostStdApp,
} from '../../../../services/application/verified-standard/app.api';
import { usePostStdAppAtt } from '../../../../services/application/verified-standard/attachment.api';
import { useGetSealTypeOptions } from '../../../../services/application/verified-standard/dropdown.api';
import { scrollToFirstError } from '../../../../utils/scroll';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import MSymbol from '../../../../components/icon/m-symbol';
import { Heading5 } from '../../../../components/typography/heading';
import { ButtonText, Text } from '../../../../components/typography/text';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionPaperHeader,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import Spacer from '../../../../components/surface/spacer';
import WizardBar from '../../../../components/bar/wizard-bar';
import Button from '../../../../components/button/button';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import Box from '../../../../components/box';
import Combobox from '../../../../components/dropdown/combobox';
import TextInputArea from '../../../../components/field/text-input-area';
import ServiceItemButton from '../../../../components/button/service-item-button';
import { fixedLayoutGrowMotion } from '../../../../styles/motions';
import LogoLumiture from '../../../../assets/images/logo-lumiture.svg';
import TextInput from '../../../../components/field/text-input';

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({
  seal_type: Yup.object().shape({
    value: Yup.string().required(),
  }),
  reason_for_application: Yup.string().required('This field is required'),
});

const SealInfo = () => {
  const navigate = useNavigate();
  const { appId } = useParams();

  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const setCurSubFormStep = useSetAtom(curSubFormStepAtom);
  const [postStdAppPayload, setPostStdAppPayload] = useAtom(postFinopsStdAppPayloadAtom);
  const postStdAppAttPayload = useAtomValue(postStdAppAttPayloadAtom);

  const { control, handleSubmit, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: postStdAppPayload,
    resolver: yupResolver(validationSchema),
  });
  const curCMLegalEntity = useWatch({ control, name: 'cm_legal_entity' });
  const curAssignee = useWatch({ control, name: 'assignee' });
  const curClient = useWatch({ control, name: 'client' });
  const curApplicationUnit = useWatch({ control, name: 'application_unit' });

  const sealTypeOptionsQuery = useGetSealTypeOptions({ cmLegalEntity: curCMLegalEntity.value });
  const postAppMutation = usePostStdApp();
  const patchAppMutation = usePatchStdApp({ appId: appId || '' });
  const postAttachmentMutation = usePostStdAppAtt({
    appId,
    onSuccess: (res, appStatus) => {
      if (appId) {
        patchAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
      } else {
        if (appStatus === 'draft') {
          postAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
        } else {
          postAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
        }
      }
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const onBackClick = () => {
    setPostStdAppPayload((prev) => ({ ...prev, ...getValues() }));
    setCurSubFormStep(2);
  };
  const onNextClick: SubmitHandler<PostFinopsStdAppPayload> = (data) => {
    setPostStdAppPayload((prev) => ({ ...prev, ...data }));
    setCurSubFormStep(4);
  };
  const onNextError = (errors: unknown) => {
    scrollToFirstError(errors);
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({
      ...postStdAppAttPayload,
      appStatus: appId ? getValues('status') : 'draft',
    });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  return (
    <form onSubmit={handleSubmit(onNextClick, onNextError)}>
      {/* Seal */}
      <SubmissionPaper>
        {/* Header */}
        <SubmissionPaperHeader>
          <Heading5>Seal</Heading5>
          <ColorfulChip text="FinOps" color="turquoise" variant="solid" />
        </SubmissionPaperHeader>
        {/* Seal Info */}
        <SubmissionSection>
          <SubmissionSectionHeader>
            <Text semibold>Seal Information</Text>
          </SubmissionSectionHeader>
          <ServiceItemButton type="button" data-state="active" showcase>
            <Box as="img" src={LogoLumiture} css={{ py: 14 }} />
          </ServiceItemButton>
          <SubmissionRow>
            {/* Assignee */}
            <SubmissionColumn>
              <Combobox
                required
                label="Assignee"
                placeholder="Select an assignee"
                options={[]}
                value={curAssignee}
                onChange={() => {}}
                disabled
              />
            </SubmissionColumn>
            {/* Application Unit */}
            <SubmissionColumn>
              <TextInput
                required
                label="Application Unit"
                placeholder="Enter an application unit"
                value={curApplicationUnit || '-'}
                onChange={() => {}}
                disabled
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Seal Type */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="seal_type"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Seal Type"
                    placeholder="Select a seal type"
                    isLoading={sealTypeOptionsQuery.isLoading}
                    options={sealTypeOptionsQuery.data || []}
                    optionSize="lg"
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Deliver To */}
            <SubmissionColumn widthRatio={'10'}>
              <Combobox
                required
                label="Deliver To"
                placeholder="Select a client"
                options={[]}
                optionSize="lg"
                value={curClient}
                onChange={() => {}}
                disabled
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Reason for Application */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="reason_for_application"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <TextInputArea
                    required
                    label="Reason for Application"
                    placeholder="Provide an explanation for the necessity of applying for the seal for this document."
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error?.message}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
        </SubmissionSection>
      </SubmissionPaper>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button
              size="md"
              variant="borderless"
              color="achromatic"
              css={{ mr: 'auto' }}
              data-track-id="btn-dialog-trigger-cancel">
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave form`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this form?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      onClick={() => onLeaveClick()}
                      data-track-id="btn-leave">
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  {getValues('status') === 'rejected' ? null : (
                    <Button
                      variant="outlined"
                      size="md"
                      onClick={() => onSaveClick()}
                      isLoading={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      css={{ width: appId ? 67 : 123 }}
                      data-track-id="btn-save">
                      <ButtonText size={14} bold>
                        {appId ? 'Save' : 'Save as draft'}
                      </ButtonText>
                    </Button>
                  )}
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      data-track-id="btn-stay">
                      <ButtonText size={14} bold>
                        Stay on this form
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button
            size="md"
            variant="outlined"
            onClick={() => onBackClick()}
            data-track-id="btn-back">
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Next */}
          <Button type="submit" size="md" data-track-id="btn-next">
            <ButtonText size={14} bold>
              Next
            </ButtonText>
          </Button>
        </Box>
      </WizardBar>
    </form>
  );
};

export default SealInfo;
