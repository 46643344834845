import { AppDetailPanels } from '../../types';
import { FC, Fragment, SVGProps, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  appBarHeightAtom,
  isAppDetailPanelsOpenAtom,
  curAppDetailPanelAtom,
  hasNewVersionAtom,
} from '../../store/atoms';
import { useQueryClient } from 'react-query';
import { useInView } from 'react-intersection-observer';
import { useLogout } from '../../services/auth.api';
import {
  useGetNotificationInfinite,
  usePutNotification,
  useGetNotificationUnread,
} from '../../services/notification.api';
import { motion } from 'framer-motion';
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
  PopoverClose,
} from '../dropdown/popover';
import Box from '../box';
import Divider from '../surface/divider';
import IconButton from '../button/icon-button';
import Spacer from '../surface/spacer';
import Typography from '../typography/deprecated-typography';
import SelectOption from '../card/select-option';
import Button from '../button/deprecated-button';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../surface/scroll-area';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from '../helper/dialog';
import Spinner from '../helper/spinner';
import Avatar from '../surface/avatar';
import ColorfulChip from '../chip/colorful-chip';
import NotificationCard from '../card/notification-card';
import { Heading5 } from '../typography/heading';
import NoNotificationBG from '../../assets/images/bg-no-notification.svg';
import ErrorMonoBG from '../../assets/images/bg-error-mono.svg';
import MSymbol from '../icon/m-symbol';
import { CenteredBoxFlex } from '../surface/centered-box';
import SystemUpdatesBG from '../../assets/images/bg-system-updates.svg';
import { Text } from '../typography/text';

const NOTIFICATION_HEADER_HEIGHT = 110;
const backgroundWrapperStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  flexGrow: 1,
  rowGap: '$5',
  mt: 80,
  px: 10,
};
interface AppBarProps {
  appLogo: FC<SVGProps<SVGSVGElement>>;
  userName?: string;
  userId?: string;
  userGroup?: string;
  userPosition?: string;
  userAvatarUrl?: string;
  userEmail?: string;
}

const AppBar = ({
  appLogo: AppLogo,
  userId,
  userName,
  userGroup,
  userPosition,
  userAvatarUrl,
  userEmail,
}: AppBarProps) => {
  const logout = useLogout();
  const navigate = useNavigate();
  const appBarHeight = useAtomValue(appBarHeightAtom);
  const hasNewVersion = useAtomValue(hasNewVersionAtom);
  const [isSystemUpdatesDialogOpen, setIsSystemUpdatesDialogOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBellOpen, setIsBellOpen] = useState(false);
  const setCurrentApplicationPanel = useSetAtom(curAppDetailPanelAtom);
  const setIsAppDetailPanelsOpen = useSetAtom(isAppDetailPanelsOpenAtom);

  const queryClient = useQueryClient();

  const { ref, inView } = useInView();
  const unreadNotificationQueries = useGetNotificationUnread();
  const notificationQueries = useGetNotificationInfinite();
  const notificationMutate = usePutNotification(() => {
    notificationQueries.refetch();
  });

  const handleLogout = () => {
    logout.mutate();
  };
  const onReloadClick = () => {
    queryClient.invalidateQueries();
    window.location.reload();
  };

  useEffect(() => {
    if (hasNewVersion) {
      setIsSystemUpdatesDialogOpen(hasNewVersion);
    }
  }, [hasNewVersion, setIsSystemUpdatesDialogOpen]);

  useEffect(() => {
    if (inView) {
      notificationQueries.fetchNextPage();
    }
  }, [inView]);

  return (
    <Box
      css={{
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: appBarHeight,
        zIndex: 100,
        minHeight: appBarHeight,
        maxHeight: appBarHeight,
        boxShadow: '$basic',
        backgroundColor: '$white',
      }}>
      {/* App Logo + Search */}
      <Box css={{ flexGrow: 1, flexShrink: 1, pl: '30px', display: 'flex', alignItems: 'center' }}>
        {AppLogo ? (
          <Box
            onClick={() => {
              navigate('/');
            }}
            css={{ width: '135px', cursor: 'pointer' }}>
            <AppLogo />
          </Box>
        ) : null}
        {process.env.REACT_APP_NODE_ENV === 'dev' ? (
          <Typography
            as="p"
            variant={'body-medium'}
            css={{ ml: '$1', mr: 'auto' }}>{`🚧`}</Typography>
        ) : process.env.REACT_APP_NODE_ENV === 'staging' ? (
          <Typography
            as="p"
            variant={'body-medium'}
            css={{ ml: '$1', mr: 'auto' }}>{`🌟`}</Typography>
        ) : null}
      </Box>
      <Spacer axis={'horizontal'} css={{ $$size: '10px' }} />
      <>
        <Divider axis={'vertical'} css={{ $$size: '60px', backgroundColor: '$secondary20' }} />
        <CenteredBoxFlex
          css={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: '60px',
          }}>
          <DialogRoot
            open={isSystemUpdatesDialogOpen}
            onOpenChange={(open) => setIsSystemUpdatesDialogOpen(open)}>
            <DialogTrigger asChild>
              <IconButton
                aria-labelledby="system-updates"
                size="medium"
                color="secondary-primary"
                iconName="campaign"
                isIconFill={false}
              />
            </DialogTrigger>
            <DialogPortal>
              <DialogOverlay />
              <DialogContent
                css={{ p: 0, overflow: 'hidden', borderRadius: '$10', maxWidth: '655px' }}>
                {/* Header */}
                <Box css={{ height: 140, backgroundColor: '$primary90' }}>
                  <Box as="img" src={SystemUpdatesBG} />
                </Box>
                {/* Description */}
                <DialogDescription css={{ px: 2 }}>
                  <CenteredBoxFlex
                    css={{
                      borderRadius: 5,
                      px: 20,
                      py: 10,
                      my: 20,
                      mx: 40,
                      border: hasNewVersion ? '1px solid $warning60' : '1px solid $success70',
                      backgroundColor: hasNewVersion ? '$warning10' : '$success10',
                    }}>
                    <Typography
                      variant={'body'}
                      css={{ color: hasNewVersion ? '$warning90' : '$success70' }}>
                      {hasNewVersion
                        ? 'New version of MileCoolab is available, please reload the site.'
                        : 'You are in latest version of MileCoolab.'}
                    </Typography>
                  </CenteredBoxFlex>
                  <Box css={{ maxHeight: 440 }}>
                    <ScrollAreaRoot>
                      <ScrollAreaViewport>
                        <Box
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 20,
                            px: 38,
                            pt: 0,
                            pb: 20,
                          }}>
                          <Box>
                            <Heading5 css={{ mb: 8 }}>Application</Heading5>
                            <ol style={{ fontSize: 12, paddingInlineStart: 12 }}>
                              <li>
                                <Text css={{ color: '$text-secondary' }}>
                                  Launched FinOps service application workflow.
                                </Text>
                              </li>
                              <li>
                                <Text css={{ color: '$text-secondary' }}>
                                  Updated to hybrid Support Plan options (1.0 & 2.0)
                                </Text>
                              </li>
                              <li>
                                <Text css={{ color: '$text-secondary' }}>
                                  Removed Gemini Enterprise & Business editions from GWS Workspace
                                  options.
                                </Text>
                              </li>
                            </ol>
                          </Box>
                        </Box>
                        <ScrollAreaScrollbar
                          orientation="vertical"
                          css={{ background: '$white', lineHeight: '24.5px' }}>
                          <ScrollAreaThumb />
                        </ScrollAreaScrollbar>
                      </ScrollAreaViewport>
                    </ScrollAreaRoot>
                  </Box>
                </DialogDescription>
                {/* Action */}
                <Box
                  css={{
                    px: 20,
                    p: 10,
                    display: 'flex',
                    columnGap: 10,
                    justifyContent: 'flex-end',
                    boxShadow: '$basic',
                  }}>
                  {hasNewVersion ? (
                    <DialogClose asChild>
                      <Button
                        variant={'borderless'}
                        color={'primary'}
                        onClick={onReloadClick}>{`Reload`}</Button>
                    </DialogClose>
                  ) : null}
                  <DialogClose asChild>
                    <Button variant={'borderless'} color={hasNewVersion ? 'secondary' : 'primary'}>
                      {hasNewVersion ? `Later` : `Understand`}
                    </Button>
                  </DialogClose>
                </Box>
              </DialogContent>
            </DialogPortal>
          </DialogRoot>
        </CenteredBoxFlex>
      </>
      <Divider axis={'vertical'} css={{ $$size: '60px', backgroundColor: '$secondary20' }} />
      {/* Work Chat Report */}
      <CenteredBoxFlex
        css={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: '60px',
        }}>
        <Box
          as="a"
          href="https://work-76339624.workplace.com/chat/t/100088479077449/"
          target="_blank">
          <IconButton
            aria-labelledby="notifications"
            size="medium"
            color="secondary-primary"
            iconName="support_agent"
            isIconFill={false}
          />
        </Box>
      </CenteredBoxFlex>
      <Divider axis={'vertical'} css={{ $$size: '60px', backgroundColor: '$secondary20' }} />
      {/* Little Bell */}
      <CenteredBoxFlex
        css={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: '60px',
        }}>
        <PopoverRoot
          open={isBellOpen}
          onOpenChange={(open) => {
            setIsBellOpen(open);
            if (open === false) {
              unreadNotificationQueries.refetch();
              queryClient.setQueryData(['notification'], (data: any) => ({
                pages: data.pages.slice(0, 1),
                pageParams: data.pageParams.slice(0, 1),
              }));
              notificationQueries.refetch({ refetchPage: (page, index) => index === 0 });
            }
          }}>
          {/* Little Bell Trigger */}
          <Box css={{ position: 'relative' }}>
            <Box
              css={{
                position: 'absolute',
                width: 10,
                height: 10,
                right: 0,
                top: 0,
                zIndex: 80,
                borderRadius: '$rounded',
                backgroundColor:
                  unreadNotificationQueries.data?.unread &&
                  unreadNotificationQueries.data.unread > 0
                    ? '$warning90'
                    : '$transparent',
              }}
            />
            <PopoverTrigger asChild>
              <IconButton
                aria-labelledby={'notifications'}
                size={'medium'}
                color={'secondary-primary'}
                iconName={'notifications'}
                isIconFill={false}
              />
            </PopoverTrigger>
          </Box>
          {/* Little Bell Content */}
          <PopoverPortal>
            <PopoverContent
              sideOffset={5}
              align={'center'}
              css={{
                width: 400,
                borderRadius: '$5',
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 100px)',
                overflow: 'hidden',
              }}>
              {/* Title */}
              <Box>
                <Box css={{ height: 60, display: 'flex', alignItems: 'center', px: 20 }}>
                  <Typography as={`h6`} variant={`h6`}>{`Notifications`}</Typography>
                </Box>
                <Divider
                  axis={'horizontal'}
                  css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                />
                <Box
                  css={{
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 20,
                  }}>
                  {unreadNotificationQueries.isLoading ? (
                    <Spinner size="tiny" />
                  ) : (
                    <Typography variant={`body-medium`}>{`${
                      unreadNotificationQueries?.isError
                        ? '-'
                        : unreadNotificationQueries?.data?.unread ?? '-'
                    } unread`}</Typography>
                  )}
                  <Button
                    variant={`borderless`}
                    disabled={
                      notificationMutate.isLoading || unreadNotificationQueries.data?.unread === 0
                    }
                    startElement={
                      notificationMutate.isLoading ? <Spinner elementFor="button" /> : null
                    }
                    onClick={() =>
                      notificationMutate.mutate({ is_read_all: true })
                    }>{`Mark all as read`}</Button>
                </Box>
                <Divider
                  axis={'horizontal'}
                  css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                />
              </Box>
              {/* Notifications */}
              <Box css={{ height: `calc(100% - ${NOTIFICATION_HEADER_HEIGHT}px)` }}>
                <ScrollAreaRoot css={{ px: 10 }}>
                  <ScrollAreaViewport>
                    {notificationQueries.status === 'loading' ? (
                      <Box css={{ mt: 180 }}>
                        <Spinner size={'medium'} />
                      </Box>
                    ) : notificationQueries.status === 'error' ? (
                      <Box css={backgroundWrapperStyles}>
                        <Box as="img" src={ErrorMonoBG} />
                        <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
                          <Typography variant="h6">{`Something's gone wrong`}</Typography>
                          <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                          <Typography variant="body-medium">
                            {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
                          </Typography>
                        </Box>
                        <Button
                          variant="solid"
                          size={'small'}
                          isLoading={notificationQueries.isLoading}
                          onClick={() => {
                            notificationQueries.refetch();
                            unreadNotificationQueries.refetch();
                          }}>{`Refresh`}</Button>
                      </Box>
                    ) : (
                      <>
                        {notificationQueries.isSuccess &&
                        notificationQueries.data.pages.length > 0 ? (
                          <>
                            <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                            {notificationQueries.data.pages.map((page, pageIndex) => {
                              return (
                                <Fragment key={pageIndex}>
                                  {page.notifications.length > 0 ? (
                                    page.notifications.map((notification) => {
                                      if (notification.is_notifyee_notification_enabled) {
                                        return (
                                          <Fragment key={notification.id}>
                                            <NotificationCard
                                              notificationId={notification.id}
                                              onBoxClick={() => {
                                                if (notification.notification_type) {
                                                  setCurrentApplicationPanel(
                                                    notification.notification_type as AppDetailPanels
                                                  );
                                                  setIsAppDetailPanelsOpen(true);
                                                }
                                                if (notification.app_type === 'official') {
                                                  navigate(
                                                    `/applications/application-details/${notification.application_id}`
                                                  );
                                                } else {
                                                  navigate(
                                                    `/applications/detail/${notification.app_type}/${notification.product}/${notification.client_type}/${notification.application_id}`
                                                  );
                                                }
                                                setIsBellOpen(false);
                                              }}
                                            />
                                          </Fragment>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })
                                  ) : (
                                    <Box
                                      css={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                        rowGap: '$5',
                                        mt: 80,
                                        px: 10,
                                      }}>
                                      <Box as="img" src={NoNotificationBG} />
                                      <Typography as={`p`} variant={'body-medium'} align={'center'}>
                                        {`No notifications at this point`}
                                      </Typography>
                                    </Box>
                                  )}
                                </Fragment>
                              );
                            })}
                            <Box ref={ref} css={{ mb: 14 }}>
                              <Typography
                                as="p"
                                align={'center'}
                                variant={'caption'}
                                css={{ color: '$primary50' }}>
                                {notificationQueries.isFetchingNextPage
                                  ? 'Loading more...'
                                  : 'Nothing more to load'}
                              </Typography>
                            </Box>
                          </>
                        ) : null}
                      </>
                    )}
                    <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
                      <ScrollAreaThumb />
                    </ScrollAreaScrollbar>
                  </ScrollAreaViewport>
                </ScrollAreaRoot>
              </Box>
            </PopoverContent>
          </PopoverPortal>
        </PopoverRoot>
      </CenteredBoxFlex>
      <Divider axis={'vertical'} css={{ $$size: '60px', backgroundColor: '$secondary20' }} />
      {/* User Avatar, Name */}
      <Box css={{ flexGrow: 0, flexShrink: 1, flexBasis: '225px', px: '15px' }}>
        <PopoverRoot open={isMenuOpen} onOpenChange={(open) => setIsMenuOpen(open)}>
          <PopoverTrigger asChild>
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                width: 200,
                height: 40,
                px: 5,
                cursor: 'pointer',
                transition: 'background-color 0.2s linear',
                backgroundColor: isMenuOpen ? '$primary20' : '$white',
                '&:hover': {
                  backgroundColor: isMenuOpen ? '$primary20' : '$secondary3',
                },
                '&:active': {
                  backgroundColor: isMenuOpen ? '$primary20' : '$secondary5',
                },
              }}>
              <Avatar name={userName || ''} picture={userAvatarUrl || ''} />
              <Spacer axis={'horizontal'} css={{ $$size: '10px' }} />
              <Typography variant={'body-bold'}>{`${userName || '-'}`}</Typography>
              <Box
                as={motion.div}
                initial={{
                  rotate: 180,
                }}
                animate={
                  isMenuOpen
                    ? { rotate: 180, transition: { duration: 0.15 } }
                    : { rotate: 0, transition: { duration: 0.15 } }
                }
                css={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
                <MSymbol iconName={`arrow_drop_down`} />
              </Box>
            </Box>
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent
              sideOffset={5}
              align={'end'}
              css={{
                width: 340,
                px: 10,
                py: 20,
                display: 'flex',
                flexDirection: 'column',
                rowGap: '$5',
              }}>
              {/* Avatar, Name, Email */}
              <Box css={{ mx: 'auto' }}>
                <Avatar
                  name={userName || '-'}
                  picture={userAvatarUrl || ''}
                  size="lg"
                  css={{ mx: 'auto', display: 'block' }}
                />
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                <Typography as={'p'} variant={'body-bold'} align={'center'}>{`${
                  userName || '-'
                }`}</Typography>
                <Typography
                  as={'p'}
                  variant={'body-medium'}
                  align={'center'}
                  css={{ color: '$secondary70' }}>{`${userEmail || '-'}`}</Typography>
                <Box
                  css={{
                    display: 'flex',
                    mt: 6,
                    alignItems: 'center',
                    justifyContent: 'center',
                    columnGap: 6,
                  }}>
                  <ColorfulChip
                    variant="outlined"
                    color="sky"
                    dense={true}
                    text={`${userGroup || '-'}`}
                  />
                  <ColorfulChip
                    variant="outlined"
                    color="sky"
                    dense={true}
                    text={`${userPosition || '-'}`}
                  />
                </Box>
              </Box>
              <Divider
                axis={'horizontal'}
                css={{ $$size: '100%', backgroundColor: '$secondary20' }}
              />
              {/* My Account */}
              <PopoverClose asChild>
                <SelectOption onClick={() => navigate('./setting')} css={{ height: 40, pr: 4 }}>
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: 8, width: '100%' }}>
                    <MSymbol iconName="person" fill={false} />
                    <Typography variant={'body'}>{`My Account`}</Typography>
                    <MSymbol iconName="navigate_next" css={{ ml: 'auto' }} />
                  </Box>
                </SelectOption>
              </PopoverClose>
              {/* Log Out */}
              <PopoverClose asChild>
                <DialogRoot>
                  <DialogTrigger asChild>
                    <SelectOption css={{ height: 40, pr: 4 }}>
                      <Box
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: 8,
                          width: '100%',
                        }}>
                        <MSymbol iconName="logout" />
                        <Typography variant={'body'}>{`Log Out`}</Typography>
                        <MSymbol iconName="navigate_next" css={{ ml: 'auto' }} />
                      </Box>
                    </SelectOption>
                  </DialogTrigger>
                  <DialogPortal>
                    <DialogOverlay />
                    <DialogContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
                      <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                        {/* Logout Title, Close Button */}
                        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                          <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                          <DialogTitle asChild>
                            <Typography
                              as={'h5'}
                              variant={'h5'}
                              css={{ flexGrow: 1 }}>{`Log out`}</Typography>
                          </DialogTitle>
                          <DialogClose asChild>
                            <IconButton iconName="close" isIconBold={true} />
                          </DialogClose>
                        </Box>
                        {/* Description */}
                        <DialogDescription css={{ pl: 40 }}>
                          <Typography variant={'body'}>{`
                        Are you sure you want to logout ?
                        `}</Typography>
                        </DialogDescription>
                        {/* Action */}
                        <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                          <Button
                            variant={'solid'}
                            color={'primary'}
                            isLoading={logout.isLoading}
                            onClick={handleLogout}>{`Log out`}</Button>
                          <DialogClose asChild>
                            <Button variant={'borderless'} color={'secondary'}>{`Cancel`}</Button>
                          </DialogClose>
                        </Box>
                      </Box>
                    </DialogContent>
                  </DialogPortal>
                </DialogRoot>
              </PopoverClose>
            </PopoverContent>
          </PopoverPortal>
        </PopoverRoot>
      </Box>
    </Box>
  );
};

export default AppBar;
