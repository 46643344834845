import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { submissionSteppers } from '../../../store/application/constants';
import { curSubmissionStepAtom } from '../../../store/application/atoms';
import {
  postSimpStdAppAttPayloadAtom,
  postSimpGCPStdAppPayloadAtom,
  postSimpGWSStdAppPayloadAtom,
} from '../../../store/application/create/simplified/atoms';
import Box from '../../../components/box';
import { Heading4 } from '../../../components/typography/heading';
import Spacer from '../../../components/surface/spacer';
import Stepper from '../../../components/helper/deprecated-stepper';

const SubmissionFormPage = () => {
  const { state } = useLocation();
  const { appType, product, clientType } = useParams();
  const curSubmissionStep = useAtomValue(curSubmissionStepAtom);
  const resetCurSubmissionStep = useResetAtom(curSubmissionStepAtom);
  const resetPostSimpGCPStdAppPayload = useResetAtom(postSimpGCPStdAppPayloadAtom);
  const resetPostSimpGWSStdAppPayload = useResetAtom(postSimpGWSStdAppPayloadAtom);
  const resetPostSimpStdAppAttPayloadAtom = useResetAtom(postSimpStdAppAttPayloadAtom);

  useEffect(() => {
    if (!state?.isSimplifiedConcentChecked && appType === 'simplified') {
      // TODO: CS-908
      console.log('You, bad.');
    } else {
      window.history.replaceState(null, '');
    }
  }, [state, appType]);

  useEffect(() => {
    return () => {
      resetCurSubmissionStep();
      resetPostSimpGCPStdAppPayload();
      resetPostSimpGWSStdAppPayload();
      resetPostSimpStdAppAttPayloadAtom();
    };
  }, [
    resetCurSubmissionStep,
    resetPostSimpStdAppAttPayloadAtom,
    resetPostSimpGCPStdAppPayload,
    resetPostSimpGWSStdAppPayload,
  ]);

  return (
    <Box css={{ px: 20, py: 15 }}>
      {/* Page Title */}
      <Heading4>{`Create Agreement & Quotation`}</Heading4>
      <Spacer axis="vertical" css={{ $$size: '27.5px' }} />
      {/* Stepper */}
      <Stepper
        activeStep={curSubmissionStep}
        steps={
          appType && product && clientType ? submissionSteppers[appType][product][clientType] : []
        }
      />
      <Spacer axis="vertical" css={{ $$size: '37.5px' }} />
      {appType && product && clientType ? (
        <>{submissionSteppers[appType][product][clientType][curSubmissionStep - 1].form}</>
      ) : null}
    </Box>
  );
};

export default SubmissionFormPage;
