import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { useSetAtom } from 'jotai';
import { curAppDetailTabAtom } from '../../store/atoms';
import { appListTabs } from '../../store/application/constants';
import { orderByOptions, sortByOptions } from '../../store/constants';
import { AppStatus, OrderByValue, SortByValue } from '../../types';
import { AppType, ClientType, ProductType, SelectedApp } from '../../types/application/constants';
import { useGetAppList } from '../../services/application/app-list.api';
import {
  usePostApprovalRequest,
  usePostSealRequest,
} from '../../services/application/verified-standard/app-actions.api';
import { useGetCreateAppPermission } from '../../services/application/verified-standard/condition.api';
import { getStatusColor } from '../../utils/get-color';
import { scrollToTop } from '../../utils/scroll';
import { getPaginationParamNumber } from '../../utils/get-range';
import { getFirstWordUpperCase } from '../../utils/get-wording';
import Box from '../../components/box';
import MSymbol from '../../components/icon/m-symbol';
import Spacer from '../../components/surface/spacer';
import { Heading4, Heading5, Heading6 } from '../../components/typography/heading';
import Button from '../../components/button/button';
import { ButtonText, Caption, Text } from '../../components/typography/text';
import {
  AppListTabRoot,
  AppListTabTrigger,
  AppListTabTriggerDecorator,
} from '../../components/surface/app-list-tab';
import Spinner from '../../components/helper/spinner';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../components/helper/dialog-alert';
import {
  SimpleSelectContent,
  SimpleSelectIcon,
  SimpleSelectItem,
  SimpleSelectItemText,
  SimpleSelectPortal,
  SimpleSelectRoot,
  SimpleSelectTrigger,
  SimpleSelectValue,
  SimpleSelectViewport,
} from '../../components/dropdown/simple-select';
import Pagination from '../../components/surface/pagination';
import { CenteredBoxFlex } from '../../components/surface/centered-box';
import ColorfulChip from '../../components/chip/colorful-chip';
import ApprovalStatusCaption from '../../components/typography/approval-status-caption';
import DocIcon from '../../components/icon/doc-icon';
import AwarenessChip from '../../components/chip/awareness-chip';
import CountingBox from '../../components/card/counting-box';
import Avatar from '../../components/surface/avatar';
import TextInput from '../../components/field/text-input';
import NoResultBG from '../../assets/images/bg-no-result.svg';
import ErrorBG from '../../assets/images/bg-error.svg';
import { getProductChipColor } from '../../utils/get-variant';

const AppListPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedApp, setSelectedApp] = useState<{
    appType?: AppType;
    product?: ProductType;
    clientType?: ClientType;
    appId?: string;
  }>({
    appId: '',
  });
  const setAppDetailTab = useSetAtom(curAppDetailTabAtom);
  // const setCurrentApplicationPanel = useSetAtom(curAppDetailPanelAtom);
  // const setIsAppDetailPanelsOpen = useSetAtom(isAppDetailPanelsOpenAtom);
  const [debouncedSearchTerm] = useDebounce(searchParams.get('search'), 1000);

  const createAppPermissionQuery = useGetCreateAppPermission();
  const appListQuery = useGetAppList({
    appStatus: (searchParams.get('application_status') as AppStatus | 'all') || 'all',
    sortBy: (searchParams.get('sort_by') as SortByValue) || 'modified_at',
    orderBy: (searchParams.get('order_by') as OrderByValue) || 'desc',
    offset: searchParams.get('offset') || '0',
    limit: searchParams.get('limit') || '20',
    search: debouncedSearchTerm || '',
  });
  const approvalRequestMutation = usePostApprovalRequest({
    appId: selectedApp.appId || '',
    product: selectedApp.product,
    onSettled: () => {
      navigate(
        `/applications/detail/${selectedApp.appType}/${selectedApp.product}/${selectedApp.clientType}/${selectedApp.appId}`
      );
    },
  });
  const sealRequestMutation = usePostSealRequest({
    appId: selectedApp.appId || '',
    product: selectedApp.product,
    onSettled: () => {
      navigate(
        `/applications/detail/${selectedApp.appType}/${selectedApp.product}/${selectedApp.clientType}/${selectedApp.appId}`
      );
    },
  });

  const onTabChange = (tab: 'all' | AppStatus) => {
    setSearchParams((params) => {
      params.set('application_status', tab);
      params.set('offset', '0');
      return params;
    });
  };
  const onSearch = (newValue: string) => {
    setSearchParams((params) => {
      params.set('search', newValue);
      params.set('offset', '0');
      return params;
    });
  };
  const onSortByChange = (newValue: string) => {
    setSearchParams((params) => {
      params.set('sort_by', newValue);
      params.set('offset', '0');
      return params;
    });
  };
  const onOrderByChange = (newValue: string) => {
    setSearchParams((params) => {
      params.set('order_by', newValue);
      params.set('offset', '0');
      return params;
    });
  };
  const onPageOffsetChange = (newValue: string) => {
    setSearchParams((params) => {
      params.set('offset', newValue);
      return params;
    });
  };
  const onAppClick = (options: {
    appType: AppType;
    product: ProductType;
    clientType: ClientType;
    appId: string;
  }) => {
    setAppDetailTab('submission');
    if (options.appType === 'official') {
      navigate(`/applications/application-details/${options.appId}`);
    } else {
      navigate(
        `/applications/detail/${options.appType}/${options.product}/${options.clientType}/${options.appId}`
      );
    }
  };
  const onApprovalRequestDialogOpenChange = (options: {
    open: boolean;
    selectedApp: SelectedApp;
  }) => {
    setSelectedApp(options.open ? options.selectedApp : {});
  };
  const onSealRequestDialogOpenChange = (options: { open: boolean; selectedApp: SelectedApp }) => {
    setSelectedApp(options.open ? options.selectedApp : {});
  };
  const onApprovalRequestClick = () => {
    approvalRequestMutation.mutate();
  };
  const onSealRequestClick = () => {
    sealRequestMutation.mutate();
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Box css={{ px: 20, py: 15, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box css={{ display: 'flex', alignItems: 'center' }}>
        <MSymbol iconName="edit_document" fill={false} />
        <Spacer axis="horizontal" css={{ $$size: '10px' }} />
        <Heading4>{`Application`}</Heading4>
        <Spacer axis="horizontal" css={{ $$size: '20px' }} />
        <Button
          css={{ width: 168 }}
          onClick={() => {
            navigate('../applications/create');
          }}
          startElement={
            <MSymbol
              iconName="add"
              weight={700}
              css={{ color: 'inherit', fontSize: '16px !important' }}
            />
          }
          isLoading={createAppPermissionQuery.isLoading}
          disabled={
            createAppPermissionQuery.isLoading ||
            !createAppPermissionQuery.data?.show_apply_document ||
            createAppPermissionQuery.isError
          }
          size="sm">
          <ButtonText size={'14'} bold>
            Agreement & Quo.
          </ButtonText>
        </Button>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '25px' }} />
      {/* Applications Tab */}
      <AppListTabRoot>
        {appListTabs.map((appListTab) => (
          <AppListTabTrigger
            key={appListTab.id}
            onClick={() => onTabChange(appListTab.value as 'all' | AppStatus)}>
            {appListTab.value === 'all' || appListTab.value === 'draft' ? null : (
              <Box
                css={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '$rounded',
                  backgroundColor: getStatusColor(appListTab.value as string),
                }}
              />
            )}
            <ButtonText
              size="14"
              bold={searchParams.get('application_status') === appListTab.value}
              css={{
                color:
                  searchParams.get('application_status') === appListTab.value
                    ? '$primary50'
                    : '$secondary70',
              }}>{`${appListTab.name}`}</ButtonText>
            <AppListTabTriggerDecorator
              tabValue={appListTab.value as 'all' | AppStatus}
              curAppListTab={(searchParams.get('application_status') || 'all') as 'all' | AppStatus}
            />
          </AppListTabTrigger>
        ))}
      </AppListTabRoot>
      <Spacer axis="vertical" css={{ $$size: '25px' }} />
      {/* Control Bar */}
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {/* Control Bar Left */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
          {/* Search */}
          <Box css={{ width: 240 }}>
            <TextInput
              value={searchParams.get('search') || ''}
              onChange={(newValue) => onSearch((newValue || '') as string)}
              placeholder="Search"
              clearable={Boolean(searchParams.get('search') && searchParams.get('search') !== '')}
              endElement={
                <Box css={{ display: 'flex', alignItems: 'center', mr: 8 }}>
                  <MSymbol
                    iconName="search"
                    size={20}
                    weight={700}
                    css={{ color: '$achromatic30' }}
                  />
                </Box>
              }
            />
          </Box>
        </Box>
        {/* Control Bar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
          {/* Sort */}
          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6, width: 240 }}>
            <Text css={{ whiteSpace: 'nowrap', color: '$achromatic70' }}>{`Sort by`}</Text>
            <SimpleSelectRoot
              value={searchParams.get('sort_by') || 'modified_at'}
              onValueChange={(newValue) => onSortByChange(newValue)}>
              <SimpleSelectTrigger>
                <Box css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <SimpleSelectValue placeholder="Modified Date" />
                </Box>
                <SimpleSelectIcon asChild>
                  <Box css={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                    <MSymbol iconName="arrow_drop_down" css={{ color: 'inherit' }} />
                  </Box>
                </SimpleSelectIcon>
              </SimpleSelectTrigger>
              <SimpleSelectPortal>
                <SimpleSelectContent position="popper" sideOffset={4}>
                  <SimpleSelectViewport>
                    {sortByOptions.map((sortByOption) => (
                      <SimpleSelectItem key={sortByOption.id} value={sortByOption.value as string}>
                        <SimpleSelectItemText>{`${sortByOption.name}`}</SimpleSelectItemText>
                      </SimpleSelectItem>
                    ))}
                  </SimpleSelectViewport>
                </SimpleSelectContent>
              </SimpleSelectPortal>
            </SimpleSelectRoot>
          </Box>
          {/* Order */}
          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6, width: 240 }}>
            <Text css={{ whiteSpace: 'nowrap', color: '$achromatic70' }}>{`Order by`}</Text>
            <SimpleSelectRoot
              value={searchParams.get('order_by') || 'desc'}
              onValueChange={(newValue) => onOrderByChange(newValue)}>
              <SimpleSelectTrigger>
                <Box css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <SimpleSelectValue placeholder="Descending (Z → A)" />
                </Box>
                <SimpleSelectIcon asChild>
                  <Box css={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                    <MSymbol iconName="arrow_drop_down" css={{ color: 'inherit' }} />
                  </Box>
                </SimpleSelectIcon>
              </SimpleSelectTrigger>
              <SimpleSelectPortal>
                <SimpleSelectContent position="popper" sideOffset={4}>
                  <SimpleSelectViewport>
                    {orderByOptions.map((orderByOption) => (
                      <SimpleSelectItem
                        key={orderByOption.id}
                        value={orderByOption.value as string}>
                        <SimpleSelectItemText>{`${orderByOption.name}`}</SimpleSelectItemText>
                      </SimpleSelectItem>
                    ))}
                  </SimpleSelectViewport>
                </SimpleSelectContent>
              </SimpleSelectPortal>
            </SimpleSelectRoot>
          </Box>
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '17px' }} />
      {/* App List */}
      {appListQuery.isLoading ? (
        <CenteredBoxFlex css={{ flex: 1 }}>
          <Spinner size="large" />
        </CenteredBoxFlex>
      ) : appListQuery.isSuccess ? (
        <>
          {appListQuery.data.applications.length > 0 ? (
            <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
              {appListQuery.data.applications.map((app) => (
                <Box key={app.application_id}>
                  {/* App List Card */}
                  <Box
                    css={{
                      backgroundColor: '$white',
                      borderRadius: 10,
                      border: 'solid 1px $func-border-light',
                    }}>
                    {/* App List Card Body */}
                    <Box
                      css={{
                        px: 20,
                        py: 14,
                        cursor: 'pointer',
                        transition: '$colors',
                        '&:hover': {
                          backgroundColor: '$primary10',
                        },
                        '&:active': {
                          backgroundColor: '$primary20',
                        },
                      }}
                      onClick={() =>
                        onAppClick({
                          appType: app.app_type as AppType,
                          product: app.product as ProductType,
                          clientType: app.client_type as ClientType,
                          appId: app.application_id,
                        })
                      }>
                      <Box css={{ display: 'flex', columnGap: 60 }}>
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 10,
                            flexBasis: `calc(40% - 30px)`,
                          }}>
                          {/* App List Card Doc Icon */}
                          <Box css={{ flexShrink: 0 }}>
                            <DocIcon
                              docType={app.icon_map.doc_type}
                              docIconBGColor={app.icon_map.doc_icon_bg}
                              docIconStatus={app.icon_map.doc_icon_status}
                            />
                          </Box>
                          {/* App List Card App Name */}
                          <Box css={{ flex: 1, flexBasis: `calc(60% - 30px)` }}>
                            <Box
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <ButtonText size={'16'} bold>{`${app.name}`}</ButtonText>
                            </Box>
                          </Box>
                        </Box>
                        {/* App List Card Approval Progress */}
                        <Box css={{ display: 'flex', columnGap: 10, flex: 1 }}>
                          {app.process_steps?.length > 0 &&
                            app.process_steps.map((process) => (
                              <Box key={process.process_step_item_id} css={{ flex: 1 }}>
                                <ButtonText
                                  size="12"
                                  css={{
                                    color:
                                      app.application_status === 'signed'
                                        ? '$primary50'
                                        : process.is_process_step_item_finished
                                        ? '$warning60'
                                        : '$achromatic20',
                                  }}>{`${process.process_step_item_name}`}</ButtonText>
                                <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                                <Box
                                  css={{
                                    height: 4,
                                    backgroundColor:
                                      app.application_status === 'signed'
                                        ? '$primary50'
                                        : process.is_process_step_item_finished
                                        ? '$warning60'
                                        : '$achromatic20',
                                  }}
                                />
                              </Box>
                            ))}
                        </Box>
                      </Box>
                      <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                      <Box css={{ display: 'flex', columnGap: 60 }}>
                        {/* App List Card Chips */}
                        <Box
                          css={{
                            flexBasis: `calc(40% - 30px)`,
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 10,
                            rowGap: 4,
                            flexWrap: 'wrap',
                          }}>
                          {app.urgent_level ? (
                            <AwarenessChip awarenessType="urgent" urgentLevel={app.urgent_level} />
                          ) : null}
                          {app.is_payment_term_awareness ? (
                            <AwarenessChip
                              awarenessType="payment"
                              isPayment={app.is_payment_term_awareness}
                            />
                          ) : null}
                          {/* TODO: 以後再加回 */}
                          {/* {app.expired_contract_code ? (
                            <AwarenessChip
                              awarenessType="expired"
                              expiredCode={app.expired_contract_code}
                            />
                          ) : null} */}
                        </Box>
                        {/* App List Card Peoples */}
                        <Box
                          css={{
                            display: 'flex',
                            columnGap: 8,
                            flexBasis: `calc(60% - 30px)`,
                          }}>
                          {/* App List Card Applicant */}
                          <Box css={{ flexBasis: `calc(${100 / 3}% - ${8 / 3}px)` }}>
                            <Caption css={{ color: '$achromatic70' }}>Attention To</Caption>
                            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                            <Box
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: '$1',
                              }}>
                              {app.attention_to_user_info.id ? (
                                <Avatar
                                  name={app.attention_to_user_info.name || '-'}
                                  picture={app.attention_to_user_info.picture || ''}
                                  css={{ mr: 8 }}
                                  size="sm"
                                />
                              ) : null}
                              <Text semibold>{`${app.attention_to_user_info.name ?? '-'}`}</Text>
                            </Box>
                          </Box>
                          {/* App List Card Next Approver */}
                          <Box css={{ flexBasis: `calc(${100 / 3}% - ${8 / 3}px)` }}>
                            <Caption css={{ color: '$achromatic70' }}>Next Approver</Caption>
                            <Box
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: '$1',
                              }}>
                              {app.next_approver ? (
                                <Avatar
                                  name={app.next_approver?.name || '-'}
                                  picture={app.next_approver?.picture || ''}
                                  css={{ mr: 8 }}
                                  size="sm"
                                />
                              ) : null}
                              <Text semibold>{`${app.next_approver?.name ?? '-'}`}</Text>
                            </Box>
                          </Box>
                          {/* App List Card Modified At */}
                          <Box css={{ flexBasis: `calc(${100 / 3}% - ${8 / 3}px)` }}>
                            <Caption css={{ color: '$achromatic70' }}>Last Modified</Caption>
                            <Text css={{ mt: '$1' }}>
                              {app.modified_at
                                ? moment(app.modified_at).format('MM/DD/YY HH:mm A (Z)')
                                : '-'}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* App List Card Footer */}
                    <Box
                      css={{
                        px: 20,
                        py: 14,
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: 60,
                        borderTop: 'solid 1px $func-border-light',
                      }}>
                      {/* App List Card Info */}
                      <Box css={{ display: 'flex', alignItems: 'center', columnGap: 40 }}>
                        {/* App List Card App Status */}
                        <Box css={{ width: 90 }}>
                          {app.is_display_application_status ? (
                            <ApprovalStatusCaption status={app.application_status} />
                          ) : null}
                        </Box>
                        {/* App List Card Attachment Length */}
                        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6 }}>
                          <CountingBox countingNumber={app.attachment_count || 0} />
                          <MSymbol
                            iconName={`attach_file`}
                            fill={false}
                            css={{ color: '$achromatic70', fontSize: '18px !important' }}
                          />
                        </Box>
                        {/* App List Card Comment Length */}
                        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6 }}>
                          <CountingBox countingNumber={app.comment_count || 0} />
                          <MSymbol
                            iconName={`chat`}
                            fill={false}
                            css={{ color: '$achromatic70', fontSize: '18px !important' }}
                          />
                        </Box>
                        {/* App List Card App Type, Product */}
                        <Box
                          css={{ width: 90, display: 'flex', columnGap: 6, alignItems: 'center' }}>
                          <ColorfulChip
                            variant="solid"
                            color={
                              app.app_type === 'official'
                                ? 'navy'
                                : app.app_type === 'simplified'
                                ? 'turquoise'
                                : 'sky'
                            }
                            text={`${getFirstWordUpperCase(app.app_type)}`}
                          />
                          <ColorfulChip
                            variant="solid"
                            color={getProductChipColor({ product: app.product })}
                            text={`${
                              app.product === 'finops' ? 'FinOps' : app.product.toUpperCase()
                            }`}
                          />
                        </Box>
                      </Box>
                      {/* App List Card Action Buttons */}
                      <Box css={{ display: 'flex', ml: 'auto' }}>
                        {app.action_map.is_display_button_approval_request ? (
                          <DialogAlertRoot
                            onOpenChange={(open) => {
                              onApprovalRequestDialogOpenChange({
                                open,
                                selectedApp: {
                                  appType: app.app_type,
                                  product: app.product,
                                  clientType: app.client_type,
                                  appId: app.application_id,
                                },
                              });
                            }}>
                            {/* Approval Request */}
                            <DialogAlertTrigger asChild>
                              <Button
                                size="md"
                                variant="outlined"
                                startElement={
                                  <MSymbol
                                    iconName="how_to_reg"
                                    weight={700}
                                    size={16}
                                    fill={true}
                                    css={{ color: 'inherit' }}
                                  />
                                }>
                                <ButtonText size={14} bold css={{ whiteSpace: 'nowrap' }}>
                                  Approval Request
                                </ButtonText>
                              </Button>
                            </DialogAlertTrigger>
                            <DialogAlertPortal>
                              <DialogAlertOverlay />
                              <DialogAlertContent
                                css={{
                                  px: 30,
                                  py: 20,
                                  borderRadius: '$10',
                                  maxWidth: '655px',
                                }}>
                                <Box
                                  css={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: 30,
                                  }}>
                                  {/* Approval Request Dialog Title, Dialog Close Button */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: 15,
                                    }}>
                                    <MSymbol
                                      iconName="error"
                                      weight={700}
                                      size={30}
                                      css={{ color: '$error60' }}
                                    />
                                    <DialogAlertTitle asChild>
                                      <Heading5
                                        css={{
                                          flexGrow: 1,
                                        }}>{`Approval Request`}</Heading5>
                                    </DialogAlertTitle>
                                  </Box>
                                  {/* Dialog Description */}
                                  <DialogAlertDescription css={{ px: 40 }}>
                                    <Text>
                                      {`Once the application is applied for`}{' '}
                                      <Text css={{ display: 'inline-block', color: '$primary50' }}>
                                        approval request
                                      </Text>{' '}
                                      {`, it cannot be revised again. Are you sure you want to proceed?`}
                                    </Text>
                                  </DialogAlertDescription>
                                  {/* Dialog Action */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      columnGap: 20,
                                    }}>
                                    <Button
                                      size="md"
                                      color="primary"
                                      spinnerColor="primary"
                                      isLoading={approvalRequestMutation.isLoading}
                                      disabled={approvalRequestMutation.isLoading}
                                      css={{ width: 153 }}
                                      onClick={() => onApprovalRequestClick()}>
                                      <ButtonText size={'14'} bold>
                                        Approval Request
                                      </ButtonText>
                                    </Button>
                                    <DialogAlertCancel asChild>
                                      <Button
                                        size="md"
                                        variant="borderless"
                                        color="achromatic"
                                        disabled={approvalRequestMutation.isLoading}>
                                        <ButtonText size={'14'} bold>
                                          Cancel
                                        </ButtonText>
                                      </Button>
                                    </DialogAlertCancel>
                                  </Box>
                                </Box>
                              </DialogAlertContent>
                            </DialogAlertPortal>
                          </DialogAlertRoot>
                        ) : null}
                        {app.action_map.is_display_button_seal_request ? (
                          <DialogAlertRoot
                            onOpenChange={(open) => {
                              onSealRequestDialogOpenChange({
                                open,
                                selectedApp: {
                                  appType: app.app_type,
                                  product: app.product,
                                  clientType: app.client_type,
                                  appId: app.application_id,
                                },
                              });
                            }}>
                            {/* Seal Request */}
                            <DialogAlertTrigger asChild>
                              <Button
                                size="md"
                                variant="outlined"
                                startElement={
                                  <MSymbol
                                    iconName="approval"
                                    weight={700}
                                    size={16}
                                    fill={false}
                                    css={{ color: 'inherit' }}
                                  />
                                }>
                                <ButtonText size={14} bold css={{ whiteSpace: 'nowrap' }}>
                                  Seal Request
                                </ButtonText>
                              </Button>
                            </DialogAlertTrigger>
                            <DialogAlertPortal>
                              <DialogAlertOverlay />
                              <DialogAlertContent
                                css={{
                                  px: 30,
                                  py: 20,
                                  borderRadius: '$10',
                                  maxWidth: '655px',
                                }}>
                                <Box
                                  css={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: 30,
                                  }}>
                                  {/* Seal Request Dialog Title, Dialog Close Button */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: 15,
                                    }}>
                                    <MSymbol
                                      iconName="error"
                                      weight={700}
                                      size={30}
                                      css={{ color: '$error60' }}
                                    />
                                    <DialogAlertTitle asChild>
                                      <Heading5
                                        css={{
                                          flexGrow: 1,
                                        }}>{`Seal Request`}</Heading5>
                                    </DialogAlertTitle>
                                  </Box>
                                  {/* Dialog Description */}
                                  <DialogAlertDescription css={{ px: 40 }}>
                                    <Text>
                                      {`Once the application is applied for`}{' '}
                                      <Text css={{ display: 'inline-block', color: '$primary50' }}>
                                        seal request
                                      </Text>{' '}
                                      {`, it cannot be revised again. Are you sure you want to proceed?`}
                                    </Text>
                                  </DialogAlertDescription>
                                  {/* Dialog Action */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      columnGap: 20,
                                    }}>
                                    <Button
                                      size="md"
                                      color="primary"
                                      spinnerColor="primary"
                                      isLoading={sealRequestMutation.isLoading}
                                      disabled={sealRequestMutation.isLoading}
                                      css={{ width: 121 }}
                                      onClick={() => onSealRequestClick()}>
                                      <ButtonText size={'14'} bold>
                                        Seal Request
                                      </ButtonText>
                                    </Button>
                                    <DialogAlertCancel asChild>
                                      <Button
                                        size="md"
                                        variant="borderless"
                                        color="achromatic"
                                        disabled={sealRequestMutation.isLoading}>
                                        <ButtonText size={'14'} bold>
                                          Cancel
                                        </ButtonText>
                                      </Button>
                                    </DialogAlertCancel>
                                  </Box>
                                </Box>
                              </DialogAlertContent>
                            </DialogAlertPortal>
                          </DialogAlertRoot>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <CenteredBoxFlex css={{ flex: 1, columnGap: 20 }}>
              <Box as="img" src={NoResultBG} />
              <Text semibold>{`No Matching Results`}</Text>
            </CenteredBoxFlex>
          )}
          <>
            <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 20,
                justifyContent: 'flex-end',
              }}>
              <Caption css={{ color: '$text-secondary' }}>{`${
                appListQuery.data?.application_count || '-'
              } Applications`}</Caption>
              {/* Pagination */}
              <Pagination
                curPageOffset={getPaginationParamNumber({
                  num: searchParams.get('offset') || '0',
                  type: 'offset',
                })}
                pageOffset={getPaginationParamNumber({
                  num: searchParams.get('offset') || '0',
                  type: 'offset',
                })}
                pageLimit={getPaginationParamNumber({
                  num: searchParams.get('limit') || '20',
                  type: 'limit',
                })}
                setPageOffset={({ newValue }) => onPageOffsetChange(newValue?.toString() || '0')}
                pageTotal={appListQuery.data?.application_count || 20}
              />
            </Box>
          </>
        </>
      ) : appListQuery.isError ? (
        <CenteredBoxFlex
          css={{
            flexGrow: 1,
            rowGap: '$5',
          }}>
          <Box as="img" src={ErrorBG} />
          <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
            <Heading6>{`Something's gone wrong`}</Heading6>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Text semibold>
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Text>
          </Box>
          <Button
            variant="solid"
            size={'sm'}
            onClick={() => appListQuery.refetch()}>{`Refresh`}</Button>
        </CenteredBoxFlex>
      ) : null}
    </Box>
  );
};

export default AppListPage;
