import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import moment from 'moment';
import { getInitDomainInfo, getInitSpecialDeal } from '../../utils/get-variant';
import {
  PostStdAppAttPayload,
  PostGCPStdAppPayload,
  SpecialDealLite,
  SpecialDealFlex,
  SpecialDealNormal,
  PostPLPSStdAppPayload,
  PostGWSStdAppPayload,
  PostFinopsStdAppPayload,
} from '../../types/application/constants';
import { DateISO } from '../../types';

// TODO: Verified 後刪掉
const initCurSubmissionStepAtom = atom(1);
export const curSubmissionStepAtom = atomWithDefault((get) => get(initCurSubmissionStepAtom));

const initCurSubFormStepAtom = atom(1);
export const curSubFormStepAtom = atomWithDefault((get) => get(initCurSubFormStepAtom));

export const initPostStdAppAttPayloadAtom = atom<PostStdAppAttPayload>({
  google_addendum: null,
  company_registration: null,
  fsr_mail: null,
  ceo_approval: null,
  gm_approval: null,
  seal_file: null,
  random_file: null,
});

export const postStdAppAttPayloadAtom = atomWithDefault<PostStdAppAttPayload>((get) =>
  get(initPostStdAppAttPayloadAtom)
);

export const initIsEditDataLoadedAtom = atom<boolean>(false);
export const isEditDataLoadedAtom = atomWithDefault<boolean>((get) =>
  get(initIsEditDataLoadedAtom)
);

export const initPostGCPStdAppPayloadAtom = atom<PostGCPStdAppPayload>({
  // * General Info
  app_type: 'verified',
  product: 'gcp',
  status: 'pending',
  client_type: 'standard',
  cm_legal_entity: { name: '', value: '' },
  cm_attention_to: { name: '', value: '' },
  cm_email: '',
  cm_picture: '',
  cm_country_calling_code: { name: '', value: '' },
  cm_phone_number: '',
  cm_extension_number: '',
  quotation_validity: moment(new Date()).add(14, 'days').toISOString() as DateISO,
  agreement_term: undefined,
  automatically_renew_term: undefined,
  currency: { name: '', value: '' },
  exchange_rate: { name: '', value: '' },
  agreement_commencement_date: moment(new Date()).add(14, 'days').toISOString() as DateISO,
  agreement_end_date: undefined,

  customer_type: 'new',
  client: { name: '', value: '' },
  client_source: undefined,
  client_country: { name: '', value: '' },
  tax_reg_number: '',
  client_address: '',
  client_attention_to: '',
  client_email: '',
  client_country_calling_code: { name: '', value: '' },
  client_phone_number: '',
  client_extension_number: '',

  // * Service Info
  payment_term: { name: '', value: 0 },
  official_pricing_discount_type: { name: 'No discount', value: 'no_discount' },
  official_pricing_discount_value: 0,
  official_pricing_discount_currency: { name: '', value: '' },
  support_plan: { name: '', value: '' },
  estimated_mrr: undefined,
  is_issued_form: 'no',
  reason_for_discount_rate: '',
  special_deal: {
    selected_deals: [],
    edp: getInitSpecialDeal({ deal: 'edp' }) as SpecialDealNormal,
    cnsp: getInitSpecialDeal({ deal: 'cnsp' }) as SpecialDealNormal,
    google_full_b2b: getInitSpecialDeal({ deal: 'google_full_b2b' }) as SpecialDealNormal,
    cm_b2b_lite: getInitSpecialDeal({ deal: 'cm_b2b_lite' }) as SpecialDealLite,
    flex: getInitSpecialDeal({ deal: 'flex' }) as SpecialDealFlex,
  },
  memo: '',

  // * Seal Info
  assignee: { name: '', value: '' },
  application_unit: '',
  seal_type: { name: '', value: '' },
  deliver_to: { name: '', value: '' },
  reason_for_application: '',

  // * Condition
  is_cm_seal_first: false,
  urgent_level: 0, // * 0: Normal, 1: Submitted for urgent, 2: Real urgent
  is_needed_legal_approval: false,
  is_needed_finance_approval: false,
});

export const postGCPStdAppPayloadAtom = atomWithDefault<PostGCPStdAppPayload>((get) =>
  get(initPostGCPStdAppPayloadAtom)
);

export const initPostGWSStdAppPayloadAtom = atom<PostGWSStdAppPayload>({
  // * General Info
  app_type: 'verified',
  product: 'gws',
  status: 'pending',
  client_type: 'standard',
  cm_legal_entity: { name: '', value: '' },
  cm_attention_to: { name: '', value: '' },
  cm_email: '',
  cm_picture: '',
  cm_country_calling_code: { name: '', value: '' },
  cm_phone_number: '',
  cm_extension_number: '',

  client: { name: '', value: '' },
  client_source: undefined,
  client_country: { name: '', value: '' },
  tax_reg_number: '',
  client_address: '',
  currency: { name: '', value: '' },
  exchange_rate: { name: '', value: '' },
  payment_term: { name: '', value: 0 },
  quotation_validity: moment(new Date()).add(14, 'days').toISOString() as DateISO,
  automatically_renew_term: undefined,
  client_attention_to: '',
  client_email: '',
  client_country_calling_code: { name: '', value: '' },
  client_phone_number: '',
  client_extension_number: '',

  // * Service Info
  has_signed_quotation: 'no',
  official_pricing_discount_type: { name: 'No discount', value: 'no_discount' },
  official_pricing_discount_value: 0,
  official_pricing_discount_currency: { name: '', value: '' },
  support_plan: { name: '', value: '' },
  domain_infos: [{ ...getInitDomainInfo() }],
  reason_for_discount_rate: '',
  memo: '',

  // * Seal Info
  assignee: { name: '', value: '' },
  application_unit: '',
  seal_type: { name: '', value: '' },
  deliver_to: { name: '', value: '' },
  reason_for_application: '',

  // * CM PUPP
  cm_per_user_per_periods: [],

  // * Condition
  is_cm_seal_first: false,
  urgent_level: 0, // * 0: Normal, 1: Submitted for urgent, 2: Real urgent
  is_needed_legal_approval: false,
  is_needed_finance_approval: false,
});

export const postGWSStdAppPayloadAtom = atomWithDefault<PostGWSStdAppPayload>((get) =>
  get(initPostGWSStdAppPayloadAtom)
);

export const initPostPLPSStdAppPayloadAtom = atom<PostPLPSStdAppPayload>({
  // * General Info
  app_type: 'verified',
  product: 'plps',
  status: 'processing',
  client_type: 'standard',
  cm_legal_entity: { name: '', value: '' },
  cm_attention_to: { name: '', value: '' },
  cm_email: '',
  cm_picture: '',
  cm_country_calling_code: { name: '', value: '' },
  cm_phone_number: '',
  cm_extension_number: '',
  quotation_validity: moment(new Date()).toISOString() as DateISO,
  currency: { name: '', value: '' },
  exchange_rate: { name: '', value: '' },

  client: { name: '', value: '' },
  client_source: undefined,
  client_country: { name: '', value: '' },
  tax_reg_number: '',
  client_address: '',
  client_attention_to: '',
  client_email: '',
  client_country_calling_code: { name: '', value: '' },
  client_phone_number: '',
  client_extension_number: '',

  // * Service Info
  billing_account: { name: '', value: '' },
  payment_term: { name: '', value: 0 },
  official_pricing_discount_type: { name: 'No discount', value: 'no_discount' },
  official_pricing_discount_value: 0,
  official_pricing_discount_currency: { name: '', value: '' },
  is_support_plan_matched: true,
  support_plan: { name: '', value: '' },
  is_issued_form: 'no',

  // * Seal Info
  assignee: { name: '', value: '' },
  application_unit: '',
  seal_type: { name: '', value: '' },
  deliver_to: { name: '', value: '' },
  reason_for_application: '',

  // * Condition
  is_cm_seal_first: true,
  urgent_level: 0, // * 0: Normal, 1: Submitted for urgent, 2: Real urgent
  is_needed_legal_approval: false,
  is_needed_finance_approval: false,
});

export const postPLPSStdAppPayloadAtom = atomWithDefault<PostPLPSStdAppPayload>((get) =>
  get(initPostPLPSStdAppPayloadAtom)
);

export const initPostFinopsStdAppPayloadAtom = atom<PostFinopsStdAppPayload>({
  // * General Info
  app_type: 'verified',
  product: 'finops',
  status: 'processing',
  client_type: 'standard',
  cm_legal_entity: { name: '', value: '' },
  cm_attention_to: { name: '', value: '' },
  cm_email: '',
  cm_picture: '',
  cm_country_calling_code: { name: '', value: '' },
  cm_phone_number: '',
  cm_extension_number: '',
  currency: { name: '', value: '' },
  exchange_rate: { name: '', value: '' },
  payment_term: { name: '', value: 0 },

  client: { name: '', value: '' },
  client_source: undefined,
  client_country: { name: '', value: '' },
  tax_reg_number: '',
  client_address: '',
  client_attention_to: '',
  client_email: '',
  client_country_calling_code: { name: '', value: '' },
  client_phone_number: '',
  client_extension_number: '',

  // * Service Info

  service_plan: { name: '', value: '' },
  automatically_renew_term: undefined,
  customer_type: 'new',

  // * Seal Info
  assignee: { name: '', value: '' },
  application_unit: '',
  seal_type: { name: '', value: '' },
  deliver_to: { name: '', value: '' },
  reason_for_application: '',

  // * Condition
  is_cm_seal_first: true,
  urgent_level: 0, // * 0: Normal, 1: Submitted for urgent, 2: Real urgent
  is_needed_legal_approval: false,
  is_needed_finance_approval: true,
});

export const postFinopsStdAppPayloadAtom = atomWithDefault<PostFinopsStdAppPayload>((get) =>
  get(initPostFinopsStdAppPayloadAtom)
);
