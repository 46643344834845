import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useAxios } from '../../../hooks';
import { CommonAPIResponse } from '../../../types';
import {
  PostFinopsStdAppPayload,
  PostGCPStdAppPayload,
  PostGWSStdAppPayload,
  PostPLPSStdAppPayload,
} from '../../../types/application/constants';
import {
  currentApplicationsTabAtom,
  getApplicationParamsAtom,
  isToastOpenAtom,
  toastContentAtom,
} from '../../../store/atoms';

export const usePostStdApp = () => {
  const navigate = useNavigate();
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setCurrentApplicationsTab = useSetAtom(currentApplicationsTabAtom);
  const resetGetApplicationParams = useResetAtom(getApplicationParamsAtom);
  const setGetApplicationParams = useSetAtom(getApplicationParamsAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation<
    CommonAPIResponse,
    AxiosError<CommonAPIResponse>,
    PostGCPStdAppPayload | PostPLPSStdAppPayload | PostGWSStdAppPayload | PostFinopsStdAppPayload
  >({
    mutationKey: ['app', 'create'],
    mutationFn: async (
      requestBody:
        | PostGCPStdAppPayload
        | PostPLPSStdAppPayload
        | PostGWSStdAppPayload
        | PostFinopsStdAppPayload
    ) => {
      console.log('create draft payload', requestBody);
      const { data } = await axios.post(`/v1/verified_app/`, requestBody);
      return data;
    },
    onSuccess: (res, variables) => {
      // TODO: Application list 優化後要修改
      queryClient.invalidateQueries(['applications']);
      resetGetApplicationParams();
      if (variables.status === 'pending') {
        setCurrentApplicationsTab('applied_by_me');
        setGetApplicationParams((prev) => ({ ...prev, tab: 'applied_by_me' }));
      }
      if (variables.status === 'draft') {
        setCurrentApplicationsTab('drafts');
        setGetApplicationParams((prev) => ({ ...prev, tab: 'drafts' }));
      }
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
    },
    onSettled: () => {
      setIsToastOpen(true);
      navigate('/applications');
    },
  });
};

export const usePatchStdApp = (options: { appId: string }) => {
  const navigate = useNavigate();
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setCurrentApplicationsTab = useSetAtom(currentApplicationsTabAtom);
  const resetGetApplicationParams = useResetAtom(getApplicationParamsAtom);
  const setGetApplicationParams = useSetAtom(getApplicationParamsAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation<
    CommonAPIResponse,
    AxiosError<CommonAPIResponse>,
    PostGCPStdAppPayload | PostPLPSStdAppPayload | PostGWSStdAppPayload | PostFinopsStdAppPayload
  >({
    mutationKey: ['app', 'patch'],
    mutationFn: async (
      requestBody:
        | PostGCPStdAppPayload
        | PostPLPSStdAppPayload
        | PostGWSStdAppPayload
        | PostFinopsStdAppPayload
    ) => {
      const { data } = await axios.patch(`/v1/verified_app/${options.appId}/`, requestBody);
      return data;
    },
    onSuccess: (res, variables) => {
      // TODO: Application list 優化後要修改
      queryClient.invalidateQueries(['applications']);
      resetGetApplicationParams();
      if (variables.status === 'pending') {
        setCurrentApplicationsTab('applied_by_me');
        setGetApplicationParams((prev) => ({ ...prev, tab: 'applied_by_me' }));
      }
      if (variables.status === 'draft') {
        setCurrentApplicationsTab('drafts');
        setGetApplicationParams((prev) => ({ ...prev, tab: 'drafts' }));
      }
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
    },
    onSettled: () => {
      setIsToastOpen(true);
      navigate('/applications');
    },
  });
};

export const useDeleteStdApp = (options: { appId: string; onSettled?: () => void }) => {
  const navigate = useNavigate();
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);

  return useMutation<CommonAPIResponse, AxiosError<CommonAPIResponse>>({
    mutationKey: ['app', 'delete'],
    mutationFn: async () => {
      const { data } = await axios.delete(`/v1/verified_app/${options.appId}`);
      return data;
    },
    onSuccess: (res) => {
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['app', 'verified', options.appId]);
      if (options.onSettled) options.onSettled();
      setIsToastOpen(true);
      navigate('/applications');
    },
  });
};
