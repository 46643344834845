import { useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { subFormSteppers } from '../../../store/application/constants';
import { curSubFormStepAtom } from '../../../store/application/atoms';
import { ProductType } from '../../../types/application/constants';
import { getCreateAppWorkflow } from '../../../utils/get-variant';
import Box from '../../../components/box';
import { Heading4, Heading5 } from '../../../components/typography/heading';
import Spacer from '../../../components/surface/spacer';
import SubFormStepper from '../../../components/helper/sub-form-stepper';
import Button from '../../../components/button/button';
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
} from '../../../components/dropdown/popover';
import MSymbol from '../../../components/icon/m-symbol';
import { ButtonText } from '../../../components/typography/text';

interface SubFormPageProps {
  curSubFormStep: number;
  product: ProductType;
  clientType: ClientTypes;
}

const renderForm = ({ curSubFormStep, product, clientType }: SubFormPageProps) => {
  return (
    <>
      {/* Stepper */}
      <SubFormStepper
        stepperName={`sub-form-${product}-${clientType}`}
        activeStep={curSubFormStep}
        steps={subFormSteppers[product][clientType]}
      />
      <Spacer axis="vertical" css={{ $$size: '37.5px' }} />
      <>{subFormSteppers[product][clientType][curSubFormStep - 1].form}</>
    </>
  );
};

const SubFormPage = () => {
  const { product, clientType } = useParams();
  const curSubFormStep = useAtomValue(curSubFormStepAtom);

  return (
    <Box css={{ px: 20, py: 15, flex: 1, position: 'relative' }}>
      {/* Page Header */}
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {/* Page Title */}
        <Heading4>{`Create Agreement & Quotation`}</Heading4>
        {/* Workflow Popover */}
        {product ? (
          <Box>
            <PopoverRoot>
              <PopoverTrigger asChild>
                <Button
                  variant="borderless"
                  size="md"
                  startElement={<MSymbol iconName="info" css={{ color: 'inherit' }} />}>
                  <ButtonText size={14} bold>
                    Workflow
                  </ButtonText>
                </Button>
              </PopoverTrigger>
              <PopoverPortal>
                <PopoverContent sideOffset={5} align="end" css={{ p: 25 }}>
                  <Heading5>{getCreateAppWorkflow({ product }).title}</Heading5>
                  <Box
                    as="img"
                    src={getCreateAppWorkflow({ product }).image}
                    css={{ maxWidth: 910, mt: 20 }}
                  />
                </PopoverContent>
              </PopoverPortal>
            </PopoverRoot>
          </Box>
        ) : null}
      </Box>
      <Spacer axis="vertical" css={{ $$size: '27.5px' }} />
      {product && clientType
        ? renderForm({
            curSubFormStep,
            product: product as ProductType,
            clientType: clientType as ClientTypes,
          })
        : null}
    </Box>
  );
};

export default SubFormPage;
